import React, {useCallback, useState, useEffect} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import '../styles/headerAccount.css';
import Empty from "../utils/Empty";

function HeaderAccount({locale, popup, onPopup, setMessage, response, setResponse, account, user, configuration, setConfiguration, navigation, devices, setDevices}) {
    const [tab, setTab] = useState('account');
    const [toggle, setToggle] = useState('');
    const [billingAddressAddress, setBillingAddressAddress] = useState(account.billing.address.address);
    const [billingAddressCity, setBillingAddressCity] = useState(account.billing.address.city);
    const [billingAddressCountry, setBillingAddressCountry] = useState(account.billing.address.country);
    const [billingAddressState, setBillingAddressState] = useState(account.billing.address.state);
    const [billingAddressZip, setBillingAddressZip] = useState(account.billing.address.zip);
    const [billingAmount, setBillingAmount] = useState(account.billing.amount);
    const [displayName, setDisplayName] = useState(account.displayName);
    const [optionsBiometrics, setOptionsBiometrics] = useState(account.options.biometrics);
    const [optionsChallenges, setOptionsChallenges] = useState(account.options.challenges);
    const [optionsFitness, setOptionsFitness] = useState(account.options.fitness);
    const [premiumInstance, setPremiumInstance] = useState({});
    const [optionsFitnessPremium, setOptionsFitnessPremium] = useState(account.options.fitnessPremium);
    const [optionsImaging, setOptionsImaging] = useState(account.options.imaging);
    const [optionsMarketplace, setOptionsMarketplace] = useState(account.options.marketplace);
    const [optionsPortal, setOptionsPortal] = useState(account.options.portal);
    const [optionsPractice, setOptionsPractice] = useState(account.options.practice);
    const [optionsScheduling, setOptionsScheduling] = useState(account.options.scheduling);
    const [optionsStorage, setOptionsStorage] = useState(account.options.storage);
    const [optionsTelehealth, setOptionsTelehealth] = useState(account.options.telehealth);
    const [optionsTriage, setOptionsTriage] = useState(account.options.triage);
    const [ccName, setCcName] = useState('');
    const [ccNumber, setCcNumber] = useState(account.billing.stripe.last4 ? 'XXXX XXXX XXXX ' + account.billing.stripe.last4 : '');
    const [ccExpiration, setCcExpiration] = useState(account.billing.stripe.month ? account.billing.stripe.month + '/' + account.billing.stripe.month : '');
    const [ccCvc, setCcCvc] = useState('');

    const closePopup = useCallback(() => {
        onPopup({view: 'close'});
    }, [onPopup]);

    function Save() {

    }

    function Update() {

    }

    function CC(data) {
        let string = data.replaceAll(' ', '');

        if (string.length <= 4) {
            setCcNumber(string);
        } else if (string.length > 4 && string.length <= 8) {
            let item = string.slice(0, 4) + ' ' + string.slice(4);
            setCcNumber(item);
        } else if (string.length > 8 && string.length <= 12) {
            let item = string.slice(0, 4) + ' ' + string.slice(4, 8) + ' ' + string.slice(8, 12) ;
            setCcNumber(item);
        } else if (string.length > 12 && string.length <= 16) {
            let item = string.slice(0, 4) + ' ' + string.slice(4, 8) + ' ' + string.slice(8, 12) + ' ' + string.slice(12, 16) ;
            setCcNumber(item);
        }
    }

    function Expiration(data) {
        let string = data.replaceAll('/', '');

        if (string.length < 2) {
            setCcExpiration(string);
        } else if (string.length === 2) {
            setCcExpiration(string + '/');
        } else if (string.length > 2 && string.length <= 6) {
            let item = string.slice(0, 2) + '/' + string.slice(2);
            setCcExpiration(item);
        }
    }

    function CVC(data) {
        if (data.length <= 4) {
            setCcCvc(data);
        }
    }

    useEffect(() => {
            if (toggle === 'fitness' && optionsFitness) {
                setOptionsFitness(false);
            } else if (toggle === 'fitness' && !optionsFitness) {
                setOptionsFitness(true);
            }
            if (toggle === 'fitnessPremium' && optionsFitnessPremium) {
                setOptionsFitnessPremium(false);
            } else if (toggle === 'fitnessPremium' && !optionsFitnessPremium && optionsFitnessPremium !== premiumInstance.getVal()) {
                mobiscroll.confirm({
                    title: locale.headerAccount.ax,
                    message: locale.headerAccount.ay,
                    okText: locale.headerAccount.az,
                    cancelText: locale.headerAccount.ba,
                    callback: (res) => {
                        if (res) {
                            setOptionsFitnessPremium(true);

                            let message = {};
                            message.type = 'wss';
                            message.path = 'devices';
                            message.action = 'get';
                            message.data = {};
                            setMessage(message);
                        } else {
                            setOptionsFitnessPremium(false);
                            premiumInstance.setVal(false);
                        }
                    }
                });
            }
            if (toggle === 'challenges' && optionsChallenges) {
                setOptionsChallenges(false);
            } else if (toggle === 'challenges' && !optionsChallenges) {
                setOptionsChallenges(true);
            }
            if (toggle === 'practice' && optionsPractice) {
                setOptionsPractice(false);
            } else if (toggle === 'practice' && !optionsPractice) {
                setOptionsPractice(true);
            }
            if (toggle === 'triage' && optionsTriage) {
                setOptionsTriage(false);
            } else if (toggle === 'triage' && !optionsTriage) {
                setOptionsTriage(true);
            }
            if (toggle === 'portal' && optionsPortal) {
                setOptionsPortal(false);
            } else if (toggle === 'portal' && !optionsPortal) {
                setOptionsPortal(true);
            }
            if (toggle === 'scheduling' && optionsScheduling) {
                setOptionsPortal(false);
            } else if (toggle === 'scheduling' && !optionsScheduling) {
                setOptionsScheduling(true);
            }
            if (toggle === 'storage' && optionsStorage) {
                setOptionsStorage(false);
            } else if (toggle === 'storage' && !optionsStorage) {
                setOptionsStorage(true);
            }
            if (toggle === 'telehealth' && optionsTelehealth) {
                setOptionsTelehealth(false);
            } else if (toggle === 'telehealth' && !optionsTelehealth) {
                setOptionsTelehealth(true);
            }
            if (toggle === 'biometrics' && optionsBiometrics) {
                setOptionsBiometrics(false);
            } else if (toggle === 'biometrics' && !optionsBiometrics) {
                setOptionsBiometrics(true);
            }
            if (toggle === 'imaging' && optionsImaging) {
                setOptionsImaging(false);
            } else if (toggle === 'imaging' && !optionsImaging) {
                setOptionsImaging(true);
            }
            if (toggle === 'marketplace' && optionsMarketplace) {
                setOptionsMarketplace(false);
            } else if (toggle === 'marketplace' && !optionsMarketplace) {
                setOptionsMarketplace(true);
            }
            if (toggle) {
                setToggle('');
            }
        },
        [toggle],
    );

    useEffect(() => {
            if (devices.length !== 0 && optionsFitnessPremium) {
                let item = devices.length * configuration.pricing.fitnessPremium;

                if (account.billing.discount) {
                    item = (account.billing.discount/100) * item;
                }

                setBillingAmount(item);
            } else if (!optionsFitnessPremium) {
                setBillingAmount(null);
            }
        },
        [devices, optionsFitnessPremium],
    );

    const GETDevices = useCallback(data => {
        if (data.devices) {
            if (devices.length !== data.devices.length) {
                setDevices(data.devices);
            }
            if (data.mobiDevices.length !== configuration.mobi.devices.length) {
                let item = configuration;
                item.mobi.devices = data.mobiDevices;
                setConfiguration(item);
            }
        }
        mobiscroll.notification.dismiss();
    }, [setDevices]);

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'devices' && navigation.view !== 'fitness') {
            GETDevices(response.data);
            setResponse({});
        }
    }, [response, GETDevices, setResponse, navigation.view])

    return (
        <Popup className="cs-header-account-popup" theme="ios" themeVariant="light" scrollLock={false} display="center" fullScreen={true} maxHeight="700" headerText={locale.headerAccount.e} buttons={tab === 'cc' ? [{text: locale.headerAccount.p, cssClass: 'cs-header-account-popup-delete', handler: function (event) {Update()}}] : [{text: locale.headerAccount.j, cssClass: 'cs-header-account-popup-save', handler: function (event) {Save()}}]} isOpen={popup.view === 'account'} onClose={closePopup}>
            <mobiscroll.TabNav className="cs-header-account-nav" display="inline">
                <mobiscroll.NavItem selected={tab === 'account'} onClick={(ev) => {setTab('account')}} ><mobiscroll.Button color="primary" className="cs-header-account-nav-button" flat={tab === 'account'}>{locale.headerAccount.a}</mobiscroll.Button></mobiscroll.NavItem>
                {user.options.owner &&
                    <mobiscroll.NavItem selected={tab === 'billing'} onClick={(ev) => {setTab('billing')}} ><mobiscroll.Button color="success" className="cs-header-account-nav-button" flat={tab === 'billing'}>{locale.headerAccount.b}</mobiscroll.Button></mobiscroll.NavItem>
                }
                {user.options.owner && !account.billing.manual &&
                    <mobiscroll.NavItem selected={tab === 'cc'} onClick={(ev) => {setTab('cc')}}><mobiscroll.Button color="warning" className="cs-header-account-nav-button" flat={tab === 'cc'}>{locale.headerAccount.c}</mobiscroll.Button></mobiscroll.NavItem>
                }
                <mobiscroll.NavItem selected={tab === 'options'} onClick={(ev) => {setTab('options')}}><mobiscroll.Button color="info" className="cs-header-account-nav-button" flat={tab === 'options'}>{locale.headerAccount.d}</mobiscroll.Button></mobiscroll.NavItem>
            </mobiscroll.TabNav>
            <mobiscroll.Form>
                {tab === 'account' &&
                    <mobiscroll.FormGroup inset className="cs-header-account-error">
                        <mobiscroll.Input disabled={account.name === 'smartdochealth'} labelStyle="floating" errorMessage={locale.headerAccount.i} type="text" name="Display Name" placeholder={locale.headerAccount.f} value={displayName} onChange={(ev) => {setDisplayName(ev.target.value)}}>
                            {locale.headerAccount.f}
                        </mobiscroll.Input>
                        <mobiscroll.Input disabled labelStyle="floating" errorMessage={locale.headerAccount.h} type="text" name="Code" placeholder={locale.headerAccount.g} value={account.code}>
                            {locale.headerAccount.g}
                        </mobiscroll.Input>
                    </mobiscroll.FormGroup>
                }
                {tab === 'billing' &&
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Input labelStyle="floating" type="text" name="Address" placeholder={locale.headerAccount.k} value={billingAddressAddress} onChange={(ev) => {setBillingAddressAddress(ev.target.value)}}>
                            {locale.headerAccount.k}
                        </mobiscroll.Input>
                        <mobiscroll.Input labelStyle="floating" type="text" name="City" placeholder={locale.headerAccount.l} value={billingAddressCity} onChange={(ev) => {setBillingAddressCity(ev.target.value)}}>
                            {locale.headerAccount.l}
                        </mobiscroll.Input>
                        <mobiscroll.Input labelStyle="floating" type="text" name="State" placeholder={locale.headerAccount.n} value={billingAddressState} onChange={(ev) => {setBillingAddressState(ev.target.value)}}>
                            {locale.headerAccount.n}
                        </mobiscroll.Input>
                        <mobiscroll.Input labelStyle="floating" type="text" name="Zip Code" placeholder={locale.headerAccount.o} value={billingAddressZip} onChange={(ev) => {setBillingAddressZip(ev.target.value)}}>
                            {locale.headerAccount.o}
                        </mobiscroll.Input>
                        <mobiscroll.Select touchUi={false} display={'bubble'} filter={true} select="single" data={configuration.mobi.countries} value={billingAddressCountry} buttons={[]} onSet={function (event, inst) {setBillingAddressCountry(inst.getVal())}} >
                            <mobiscroll.Input labelStyle="floating">{locale.headerAccount.m}</mobiscroll.Input>
                        </mobiscroll.Select>
                    </mobiscroll.FormGroup>
                }
                {tab === 'cc' &&
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.FormGroupTitle className="mbsc-bold">
                            {locale.headerAccount.u}
                        </mobiscroll.FormGroupTitle>
                        <mobiscroll.Input labelStyle="floating" type="text" name="Name" value={ccName} onChange={(ev) => {setCcName(ev.target.value)}}>{locale.headerAccount.q}</mobiscroll.Input>
                        <mobiscroll.Input labelStyle="floating" type="text" name="number" placeholder={'XXXX XXXX XXXX XXXX'} value={ccNumber} onChange={(ev) => {CC(ev.target.value)}}>{locale.headerAccount.r}</mobiscroll.Input>
                        <mobiscroll.Input labelStyle="floating" type="text" name="expiration" placeholder={'MM/YYYY'} value={ccExpiration} onChange={(ev) => {Expiration(ev.target.value)}}>{locale.headerAccount.s}</mobiscroll.Input>
                        <mobiscroll.Input labelStyle="floating" type="text" name="CVC" placeholder={'XXX'} value={ccCvc} onChange={(ev) => {CVC(ev.target.value)}}>{locale.headerAccount.t}</mobiscroll.Input>
                    </mobiscroll.FormGroup>
                }
                {tab === 'cc' &&
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Note color="primary">{locale.headerAccount.au}</mobiscroll.Note>
                    </mobiscroll.FormGroup>
                }
                {tab === 'options' && (billingAmount || account.billing.manual) &&
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Note color="info">{account.billing.manual && locale.headerAccount.be} {billingAmount && locale.headerAccount.at}{billingAmount}</mobiscroll.Note>
                    </mobiscroll.FormGroup>
                }
                {tab === 'options' &&
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Switch checked={optionsFitness} onChange={() => setToggle('fitness')}>
                            {locale.headerAccount.x}
                            <span className="mbsc-desc">{locale.headerAccount.y}</span>
                        </mobiscroll.Switch>
                    </mobiscroll.FormGroup>
                }
                {tab === 'options' && optionsFitness &&
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.FormGroupTitle className="mbsc-bold">
                            {locale.headerAccount.av}
                        </mobiscroll.FormGroupTitle>
                        <mobiscroll.Switch checked={optionsFitnessPremium} onInit={function (event, inst) {setPremiumInstance(inst)}} onChange={() => setToggle('fitnessPremium')}>
                            <span>{locale.headerAccount.z}{configuration.pricing.fitnessPremium} {locale.headerAccount.bc}</span>
                            <span className="mbsc-desc">{locale.headerAccount.aa}</span>
                        </mobiscroll.Switch>
                        <mobiscroll.Switch checked={optionsChallenges} onChange={(ev) => setToggle('challenges')}>
                            {locale.headerAccount.ab}
                            <span className="mbsc-desc">{locale.headerAccount.ac}</span>
                        </mobiscroll.Switch>
                    </mobiscroll.FormGroup>
                }
                {tab === 'options' &&
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.Switch disabled checked={optionsPractice} onChange={(ev) => setToggle('practice')}>
                            <span>{locale.headerAccount.v}{configuration.pricing.practice} {locale.headerAccount.bb}</span>
                            <span className="mbsc-desc">{locale.headerAccount.w}</span>
                        </mobiscroll.Switch>
                    </mobiscroll.FormGroup>
                }
                {tab === 'options' && optionsPractice &&
                    <mobiscroll.FormGroup inset>
                        <mobiscroll.FormGroupTitle className="mbsc-bold">
                            {locale.headerAccount.aw}
                        </mobiscroll.FormGroupTitle>
                        <mobiscroll.Switch checked={optionsTriage} onChange={(ev) => setToggle('triage')}>
                            <span>{locale.headerAccount.ah}{configuration.pricing.triage} {locale.headerAccount.bb}</span>
                            <span className="mbsc-desc">{locale.headerAccount.ai}</span>
                        </mobiscroll.Switch>
                        <mobiscroll.Switch checked={optionsPortal} onChange={(ev) => setToggle('portal')}>
                            {locale.headerAccount.ad}
                            <span className="mbsc-desc">{locale.headerAccount.ae}</span>
                        </mobiscroll.Switch>
                        <mobiscroll.Switch checked={optionsScheduling} onChange={(ev) => setToggle('scheduling')}>
                            {locale.headerAccount.af}
                            <span className="mbsc-desc">{locale.headerAccount.ag}</span>
                        </mobiscroll.Switch>
                        <mobiscroll.Switch checked={optionsStorage} onChange={(ev) => setToggle('storage')}>
                            <span>{locale.headerAccount.aj}{configuration.pricing.storage} {locale.headerAccount.bd}</span>
                            <span className="mbsc-desc">{locale.headerAccount.ak}</span>
                        </mobiscroll.Switch>
                        <mobiscroll.Switch disabled checked={optionsTelehealth} onChange={(ev) => setToggle('telehealth')}>
                            {locale.headerAccount.al}
                            <span className="mbsc-desc">{locale.headerAccount.am}</span>
                        </mobiscroll.Switch>
                        <mobiscroll.Switch disabled checked={optionsBiometrics} onChange={(ev) => setToggle('biometrics')}>
                            {locale.headerAccount.an}
                            <span className="mbsc-desc">{locale.headerAccount.ao}</span>
                        </mobiscroll.Switch>
                        <mobiscroll.Switch disabled checked={optionsImaging} onChange={(ev) => setToggle('imaging')}>
                            {locale.headerAccount.ap}
                            <span className="mbsc-desc">{locale.headerAccount.aq}</span>
                        </mobiscroll.Switch>
                        <mobiscroll.Switch disabled checked={optionsMarketplace} onChange={(ev) => setToggle('marketplace')}>
                            {locale.headerAccount.ar}
                            <span className="mbsc-desc">{locale.headerAccount.as}</span>
                        </mobiscroll.Switch>
                    </mobiscroll.FormGroup>
                }
            </mobiscroll.Form>
        </Popup>
    );
}

export default HeaderAccount;
