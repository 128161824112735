import React, {useEffect, useState, useCallback} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from 'dayjs';
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Chart as ChartJS, CategoryScale, LinearScale, ArcElement, BarElement,  PointElement, LineElement, Title, Tooltip, Legend} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import './styles/queue.css';
import Empty from './utils/Empty';
import UsersUser from './blocks/UsersUser';
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

function Queue({locale, setMessage, response, setResponse, navigation, Navigation, user}) {
    const [challenges, setChallenges] = useState([]);
    const [challenge, setChallenge] = useState({});
    const [sleep, setSleep] = useState({});
    const [activity, setActivity] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [chartData, setChartData] = useState({});
    const [date, setDate] = useState('');
    const [mobiMonth, setMobiMonth] = useState([]);
    const [mobiYear, setMobiYear] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const Date = () => {
        mobiscroll.notification.dismiss();
        mobiscroll.toast({message: locale.device.ak, duration: false, color: 'primary'});
        if (navigation.metric === 'activity') {
            Activity();
        } else if (navigation.metric === 'hr') {
            HR();
        } else if (navigation.metric === 'sleep') {
            Sleep();
        } else if (navigation.metric === 'challenge') {
            Challenge();
        }
    }

    const Browse = () => {
        if (navigation.metric === 'activity' && navigation.tab === 'day'  && ( navigation.item === 'steps' || navigation.item === 'distance' || navigation.item === 'moves' || navigation.item === 'calories' )) {
            if (refresh === false) {
                setRefresh(true);
            }
            mobiscroll.notification.dismiss();
            let dt = date;
            setDate(dayjs().format('YYYY-M-D'));
            if (dt === dayjs().format('YYYY-M-D')) {
                mobiscroll.toast({message: locale.device.ak, duration: false, color: 'primary'});
                Activity();
            }
        } else if (navigation.metric === 'activity' && navigation.tab === 'week') {
            let item = chartData;
            item.labels = [];
            item.datasets[0].data = [];
            item.datasets[0].label = locale.system.d;
            setChartData(item);
            setDate('');
            setRefresh(false);
        } else if (navigation.metric === 'activity' && navigation.tab === 'month' && !Empty(chartData)) {
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: locale.device.ak, duration: false, color: 'primary'});
            let item = chartData;
            item.labels = [];
            item.datasets[0].data = [];
            item.datasets[0].label = locale.system.e;
            setDate('');
            setRefresh(false);
            MonthYear();
        } else if (navigation.metric === 'activity' && navigation.tab === 'year' && !Empty(chartData)) {
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: locale.device.ak, duration: false, color: 'primary'});
            let item = chartData;
            item.labels = [];
            item.datasets[0].data = [];
            item.datasets[0].label = locale.system.f;
            setDate('');
            setRefresh(false);
            MonthYear();
        } else if (navigation.metric === 'challenge' && !Empty(chartData)) {
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: locale.device.ak, duration: false, color: 'primary'});
            let dt = date;
            setDate(dayjs().format('YYYY-M-D'));
            if (dt === dayjs().format('YYYY-M-D')) {
                Challenge();
            }
        } else if (navigation.metric === 'hr' && ( navigation.item === 'hr' || navigation.item === 'pressure' ) && !Empty(chartData)) {
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: locale.device.ak, duration: false, color: 'primary'});
            let dt = date;
            setDate(dayjs().format('YYYY-M-D'));
            if (dt === dayjs().format('YYYY-M-D')) {
                HR();
            }
        } else if (navigation.metric === 'sleep' && !Empty(chartData)) {
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: locale.device.ak, duration: false, color: 'primary'});
            let dt = date;
            setDate(dayjs().format('YYYY-M-D'));
            if (dt === dayjs().format('YYYY-M-D')) {
                Sleep();
            }
        }
    }

    const Activity = useCallback(() => {
        let message = {};
        message.type = 'rest';
        message.path = 'activity';
        message.action = 'get';
        message.data = {
            date: date,
            type: navigation.tab,
            metric: navigation.item
        };
        setMessage(message);
    }, [date, navigation.tab, navigation.item, setMessage]);

    const MonthYear = useCallback(() => {
        setMobiMonth([]);
        setMobiYear([]);

        let message = {};
        message.type = 'rest';
        message.path = 'monthYear';
        message.action = 'get';
        message.data = {};
        setMessage(message);
    }, [setMessage]);

    const Challenge = useCallback(() => {
        let message = {};
        message.type = 'rest';
        message.path = 'challenge';
        message.action = 'get';
        message.data = {
            date: date,
            range: navigation.tab,
            challenge: navigation.item
        };
        setMessage(message);
    }, [date, navigation.tab, navigation.item, setMessage]);

    const Challenges = useCallback(() => {
        mobiscroll.notification.dismiss();
        mobiscroll.toast({message: locale.device.ak, duration: false, color: 'primary'});
        setRefresh(true);

        let message = {};
        message.type = 'rest';
        message.path = 'challenges';
        message.action = 'get';
        message.data = {};
        setMessage(message);
    }, [locale.device.ak, setMessage]);

    const HR = useCallback(() => {
        let message = {};
        message.type = 'rest';
        message.path = 'heart';
        message.action = 'get';
        message.data = {
            date: date,
            metric: navigation.item
        };
        setMessage(message);
    }, [setMessage, date, navigation.item]);

    const HighFive = useCallback(data => {
        let date = dayjs().format('YYYY-M-D');

        let message = {};
        message.type = 'rest';
        message.path = 'highFive';
        message.action = 'post';
        message.data = {
            id: data,
            group: navigation.item,
            date: date
        };
        setMessage(message);
    }, [setMessage, navigation.item]);

    const Sleep = useCallback(() => {
        let message = {};
        message.type = 'rest';
        message.path = 'sleep';
        message.action = 'get';
        message.data = {
            date: date
        };
        setMessage(message);
    }, [setMessage, date]);

    const GETActivity = useCallback(data => {
        if (data.chartOptions) {
            setChartOptions(data.chartOptions);
        }
        if (navigation.tab !== 'year' && navigation.item === 'steps' && data.chartData) {
            let item = data.chartData;
            item.datasets[0].label = locale.home.e;
            setChartData(item);
            setActivity({
                steps: data.total,
                calories: 0,
                distance: 0,
                moves: 0
            });
        }
        if (navigation.tab !== 'year' && navigation.item === 'calories' && data.chartData) {
            let item = data.chartData;
            item.datasets[0].label = locale.home.h;
            setChartData(item);
            setActivity({
                steps: 0,
                calories: data.total,
                distance: 0,
                moves: 0
            });
        }
        if (navigation.tab !== 'year' && navigation.item === 'distance' && data.chartData) {
            let item = data.chartData;
            if (user.unit === 'metric') {
                item.datasets[0].label = locale.home.f + ' in ' + locale.home.i;
            } else if (user.unit === 'imperial') {
                item.datasets[0].label = locale.home.f + ' in ' + locale.home.ac;
            }
            setChartData(item);
            setActivity({
                steps: 0,
                calories: 0,
                distance: data.total,
                moves: 0
            });
        }
        if (navigation.tab !== 'year' && navigation.item === 'moves' && data.chartData) {
            let item = data.chartData;
            item.datasets[0].label = locale.home.g;
            setChartData(item);
            setActivity({
                steps: 0,
                calories: 0,
                distance: 0,
                moves: data.total
            });
        }
        if (navigation.tab === 'year' && data.chartData) {
            let item = data.chartData;
            item.datasets[0].label = locale.home.e;
            item.labels = item.labels.map(function(item){
                if (user.locale === 'es') {
                    item = dayjs(item).locale('es').format('MMMM')
                } else if (user.locale === 'en') {
                    item = dayjs(item).format('MMMM')
                } else if (user.locale === 'fr') {
                    item = dayjs(item).locale('fr').format('MMMM')
                }
                return item;
            });
            setChartData(item);
        }
        setRefresh(false);
        mobiscroll.notification.dismiss();
    }, [locale.home.ac, locale.home.e, locale.home.f, locale.home.g, locale.home.h, locale.home.i, navigation.item, navigation.tab, user.locale, user.unit]);

    const GETChallenges = useCallback(data => {
        if (data.challenges && data.challenges.length > 0) {
            setChallenges(data.challenges);
            Navigation({metric:'challenge', item: data.challenges[0].code, tab: 'day'});
        } else {
            mobiscroll.toast({message: locale.system.s, color: 'warning'});
        }
        mobiscroll.notification.dismiss();
    }, [Navigation]);

    const GETChallenge = useCallback(data => {
        setChallenge(data);
        setRefresh(false);
        mobiscroll.notification.dismiss();
    }, []);

    const GETHeart = useCallback(data => {
        if (data.chartOptions) {
            setChartOptions(data.chartOptions);
        }
        if (navigation.item === 'hr' && data.chartData && data.chartData.datasets.length <= 1) {
            let item = data.chartData;
            item.datasets[0].label = locale.home.l;
            setChartData(item);
        }
        if (navigation.item === 'hr' && data.chartData && data.chartData.datasets.length > 1) {
            let item = data.chartData;
            item.datasets[0].label = locale.home.z;
            item.datasets[1].label = locale.home.y;
            item.datasets[2].label = locale.home.x;
            item.datasets[3].label = locale.home.w;
            item.datasets[4].label = locale.home.ab;
            setChartData(item);
        }
        if (navigation.item === 'pressure' && data.chartData) {
            let item = data.chartData;
            item.datasets[0].label = locale.home.s;
            item.datasets[1].label = locale.home.t;
            setChartData(item);
        }
        mobiscroll.notification.dismiss();
    }, [navigation.item, locale.home.z, locale.home.y, locale.home.x, locale.home.w, locale.home.ab, locale.home.l, locale.home.s, locale.home.t]);

    const GETSleep = useCallback(data => {
        if (data.chartData) {
            let item = data.chartData;
            item.datasets[0].label = locale.home.ad;
            item.datasets[1].label = locale.home.ae;
            item.datasets[2].label = locale.home.af;
            item.datasets[3].label = locale.home.ag;
            item.datasets[4].label = locale.home.ah;
            setChartData(item);
        }
        if (data.chartOptions) {
            setChartOptions(data.chartOptions);
        }
        delete data.chartOptions;
        delete data.chartData;
        setSleep(data);
        mobiscroll.notification.dismiss();
    }, [locale.home.ad, locale.home.ae, locale.home.af, locale.home.ag, locale.home.ah]);

    const GETMonthYear = useCallback(data => {
        if (navigation.tab === 'month' && data.mobiMonth) {
            setMobiMonth(data.mobiMonth);
        } else if (navigation.tab === 'year' && data.mobiYear) {
            setMobiYear(data.mobiYear);
        }
        mobiscroll.notification.dismiss();
    }, [navigation.tab]);

    const POSTHighfive = useCallback(data => {
        if (data.toast) {
            mobiscroll.toast({message: locale.system.h, color: 'primary'});
        }
        if (data.error) {
            mobiscroll.toast({message: locale.system.i, color: 'primary'});
        }
        mobiscroll.notification.dismiss();
    }, [locale.system.h, locale.system.i]);

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'activity') {
            GETActivity(response.data);
            setResponse({});
        }
    }, [response, GETActivity, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'challenges') {
            GETChallenges(response.data);
            setResponse({});
        }
    }, [response, GETChallenges, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'challenge') {
            GETChallenge(response.data);
            setResponse({});
        }
    }, [response, GETChallenge, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'heart') {
            GETHeart(response.data);
            setResponse({});
        }
    }, [response, GETHeart, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'sleep') {
            GETSleep(response.data);
            setResponse({});
        }
    }, [response, GETSleep, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'monthYear') {
            GETMonthYear(response.data);
            setResponse({});
        }
    }, [response, GETMonthYear, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'highFive') {
            POSTHighfive(response.data);
            setResponse({});
        }
    }, [response, POSTHighfive, setResponse])

    useEffect(() => {
        if (date) {
            Date();
        }
    }, [date]);

    useEffect(() => {
        Browse();
    }, [navigation]);

    useEffect(() => {
        Navigation({tab:'day', metric: 'activity', item: 'steps'});
    }, []);

  return (
    <div className="cs-home-background">
        {!refresh && ( navigation.metric === 'activity' || navigation.metric === 'hr' || navigation.metric === 'sleep' ) && !Empty(chartData) &&
            <Bar options={chartOptions} data={chartData} />
        }
        {navigation.metric === 'activity' && !Empty(activity) &&
            <UsersUser
                locale={locale}
                user={user}
                activity={activity}
                navigation={navigation}
            />
        }
    </div>
  );
}

export default Queue;
