import React, {useCallback, useState} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import '../styles/loginReset.css';

function LoginReset({locale, popup, onPopup, setMessage, reset, account, email}) {
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const closePopup = useCallback(() => {
    onPopup({view: 'close'});
  }, [onPopup]);

  function Reset() {
    if (!password || !confirm) {
      mobiscroll.toast({message: locale.loginReset.a, color: 'danger'});
    } else if (password !== confirm) {
      mobiscroll.toast({message: locale.loginReset.b, color: 'danger'});
    } else {
      let message = {};
      message.type = 'rest';
      message.path = 'auth';
      message.action = 'post';
      message.data = {
        email: email,
        password: password,
        reset: reset,
        account: account.name,
      };
      setMessage(message);
    }
  }

  return (
      <Popup theme="ios" themeVariant="light" display="center" maxHeight="650" fullScreen={true} headerText={locale.loginReset.c} buttons={[{text: locale.loginReset.f, cssClass: 'cs-login-reset-popup-save', handler: function (event) {Reset()}}]} isOpen={popup.view === 'passwordReset'} onClose={closePopup}>
        <div className="cs-login-reset-helper">
          <p>{locale.loginReset.g}</p>
        </div>
        <div className="cs-login-reset-helper">
          <p>{locale.loginReset.h}</p>
        </div>
        <div className="cs-login-reset-helper">
          <p>{locale.loginReset.i}</p>
        </div>
        <div className="cs-login-reset-helper">
          <p>{locale.loginReset.j}</p>
        </div>
        <mobiscroll.Form>
          <mobiscroll.FormGroup inset>
            <mobiscroll.Input labelStyle="floating" type="password" name="password" passwordToggle={true} icon="none" iconAlign="right" value={password} onChange={ev => {setPassword(ev.target.value) }}>
              {locale.loginReset.d}
            </mobiscroll.Input>
            <mobiscroll.Input labelStyle="floating" type="password" name="confirm" passwordToggle={true} icon="none" iconAlign="right" value={confirm} onChange={ev => {setConfirm(ev.target.value) }}>
              {locale.loginReset.e}
            </mobiscroll.Input>
          </mobiscroll.FormGroup>
        </mobiscroll.Form>
      </Popup>
  );
}

export default LoginReset;
