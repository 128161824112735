const Mobile = ({data, setMobile}) => {
  let mobile = '';
  if (data) {
    const cleanedText = data.replace(/[^\d]/g, '');
    let formattedText = '';
    if (cleanedText.length <= 3) {
      formattedText = cleanedText;
    } else if (cleanedText.length <= 6) {
      formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3)}`;
    } else {
      formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3, 6)}-${cleanedText.slice(6, 10)}`;
    }
    mobile = formattedText;
  }
  if (mobile && setMobile) {
    setMobile(mobile);
  } else if (mobile && !setMobile) {
    return mobile;
  }
};

export default Mobile;
