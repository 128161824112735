import React, {useEffect, useState, useCallback} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import './styles/fitness.css';
import Empty from './utils/Empty';
import FitnessDevice from "./blocks/FitnessDevice";

function Fitness({locale, setMessage, user, response, setResponse, popup, onPopup, configuration, setConfiguration, devices, setDevices}) {
    const [device, setDevice] = useState({});

    function Devices() {
        let message = {};
        message.type = 'wss';
        message.path = 'devices';
        message.action = 'get';
        message.data = {};
        setMessage(message);
    }

    function Device(data) {
        let array = devices;
        array.map(function(item){
            if (item.data.name === data) {
                setDevice(item.data);
            }
            return item;
        })
        onPopup({view: 'device'});
    }

    function DevicesRows() {
        if (configuration.mobi.devices.length <= 10) {
            return configuration.mobi.devices.length;
        } else {
            return 10;
        }
    }

    function DeviceRegister() {
        mobiscroll.toast({message: locale.fitness.e, duration: false, color: 'primary'});
        let item = {};

        navigator.bluetooth.requestDevice({filters: [{services: [configuration.ble.service]}] }).then(dataDevice => {
            item.name = dataDevice.name;
            item.id = dataDevice.id;

            let message = {};
            message.type = 'wss';
            message.path = 'deviceRegister';
            message.action = 'get';
            message.data = {
                name: item.name,
                id: item.id
            };
            setMessage(message);
        }).catch(error => {
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: locale.fitness.f, color: 'warning'});
        });
    }

    function DeviceRegisterFinish(data) {
        let message = {};
        message.type = 'wss';
        message.path = 'deviceRegister';
        message.action = 'post';
        message.data = {
            name: data.name,
            id: data.id
        };
        setMessage(message);
    }

    const GETDevices = useCallback(data => {
        if (data.devices) {
            setDevices(data.devices);
            let item = configuration;
            item.mobi.devices = data.mobiDevices;
            setConfiguration(item);
        }
        mobiscroll.notification.dismiss();
    }, [setDevices]);

    const GETDeviceRegister = useCallback(data => {
        mobiscroll.notification.dismiss();
        if (data.error) {
            mobiscroll.toast({message: locale.fitness.l, color: 'warning'});
        }
        if (data.toast) {
            let name = data.name;
            let id = data.id;
            let text = name + locale.fitness.g
            mobiscroll.confirm({
                title: locale.fitness.h,
                message: text,
                okText: locale.fitness.h,
                cancelText: locale.fitness.i,
                callback: (res) => {
                    if (res) {
                        DeviceRegisterFinish({name: name, id: id});
                    }
                }
            });
        }
    }, [locale.fitness.l, locale.fitness.g, locale.fitness.h, locale.fitness.i]);

    const POSTDeviceRegister = useCallback(data => {
        if (data.device) {
            let array = devices;
            let item = {};
            item.data = data.device;
            array.push(item);

            setDevices(array);
        }
        if (data.mobiDevice) {
            let array = configuration.mobi.devices;
            array.push(data.mobiDevice);
            let item = configuration;
            item.mobi.devices = array;
            setConfiguration(item);
        }
        if (data.toast) {
            mobiscroll.toast({message: locale.fitness.j, color: 'primary'});
        }
    }, [devices, locale.fitness.j, setDevices]);

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'deviceRegister') {
            GETDeviceRegister(response.data);
            setResponse({});
        }
    }, [response, GETDeviceRegister, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'deviceRegister') {
            POSTDeviceRegister(response.data);
            setResponse({});
        }
    }, [response, POSTDeviceRegister, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'devices') {
            GETDevices(response.data);
            setResponse({});
        }
    }, [response, GETDevices, setResponse])

    useEffect(() => {
        if (devices.length === 0) {
            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: locale.fitness.d, duration: false, color: 'primary'});
            Devices();
        }
    }, []);

    return (
        <div className="mbsc-grid">
            <div className="mbsc-row">
                <div className="mbsc-col-12">
                    <mobiscroll.Card theme="ios" themeVariant="light">
                        <mobiscroll.CardContent className="cs-fitness-device-select">
                            <mobiscroll.Form>
                                {configuration.mobi.devices.length !== 0 &&
                                    <mobiscroll.Select rows={DevicesRows()} display="inline" select='single' touchUi={false} onSet={function(event, inst) {Device(inst.getVal()); inst.clear();}} filter={true} data={configuration.mobi.devices} buttons={[]} />
                                }
                                {devices.length === 0 &&
                                    <div className="mbsc-row mbsc-justify-content-center">
                                        <mobiscroll.Button disabled flat={true}>{locale.fitness.m}</mobiscroll.Button>
                                    </div>
                                }
                            </mobiscroll.Form>
                        </mobiscroll.CardContent>
                        <mobiscroll.CardFooter>
                            <div className="mbsc-grid">
                                <div className="mbsc-row mbsc-justify-content-between">
                                    <div className="mbsc-row cs-fitness-footer-left"></div>
                                    <div className="mbsc-row cs-fitness-footer-right">
                                        <mobiscroll.Button onClick={() => {DeviceRegister()}} flat={true} color="success">{locale.fitness.c}</mobiscroll.Button>
                                        {user.options.owner &&
                                            <mobiscroll.Button flat={true} color="primary"><a href='https://buy.stripe.com/eVa9AJ1KGa8L0RqeUU' target='_blank' rel='noreferrer noopener'>{locale.fitness.k}</a></mobiscroll.Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </mobiscroll.CardFooter>
                    </mobiscroll.Card>
                </div>
            </div>
            {popup.view === 'device' &&
                <FitnessDevice
                    locale={locale}
                    popup={popup}
                    onPopup={onPopup}
                    setMessage={setMessage}
                    response={response}
                    setResponse={setResponse}
                    configuration={configuration}
                    setConfiguration={setConfiguration}
                    device={device}
                    setDevice={setDevice}
                    devices={devices}
                    setDevices={setDevices}
                />
            }
        </div>
    );
}

export default Fitness;
