import React, {useState} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/header.css';
import Empty from './utils/Empty';
import {faUsers, faWatchSmart, faHospitalUser, faSquareSlidersVertical, faSquareUser, faLanguage, faMessageQuestion, faRightFromBracket, faBars} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HeaderHelp from "./blocks/HeaderHelp";
import HeaderAccount from "./blocks/HeaderAccount";
import HeaderLocale from "./blocks/HeaderLocale";

function Header({Navigation, account, Logout, locale, response, setResponse, language, Locale, navigation, Account, user, popup, onPopup, configuration, setConfiguration, width, setMessage, devices, setDevices}) {
    const [menu, setMenu] = useState(false);

    function Menu() {
        if (!menu) {
            setMenu(true);
            setTimeout(() => {
                setMenu(false);
            }, 15000);
        } else {
            setMenu(false);
        }
    }

    return (
        <div className="mbsc-grid cs-header-toolbar">
            <div className="mbsc-row mbsc-justify-content-between">
                <div className="mbsc-row">
                    {navigation.view === 'register' &&
                        <mobiscroll.Button data-icon="arrow-left5" onClick={() => Navigation({view: 'login'})} flat={true} />
                    }
                    {navigation.view !== 'register' &&
                        <div className="cs-header-logo" />
                    }
                    {width > 768 &&
                        <mobiscroll.Button disabled={navigation.view === 'home' || navigation.view === 'register' || navigation.view === 'device' || navigation.view === 'profile' || navigation.view === 'help'} onClick={() => Account()} className="cs-header-display" flat={true}>{account.displayName}</mobiscroll.Button>
                    }
                </div>
                <div className="mbsc-row">
                    {!Empty(user) && account.options.practice && (account.options.triage || account.options.scheduling) && navigation.view === 'queue' &&
                        <div title={locale.header.h} className="cs-header-navigation-container">
                            <FontAwesomeIcon className="cs-header-navigation-disabled" icon={faHospitalUser} />
                        </div>
                    }
                    {!Empty(user) && account.options.practice && (account.options.triage || account.options.scheduling) && navigation.view !== 'queue' &&
                        <div title={locale.header.h} className="cs-header-navigation-container" onClick={() => {Navigation({view:'queue'})}}>
                            <FontAwesomeIcon className="cs-header-navigation-queue" icon={faHospitalUser} />
                        </div>
                    }
                    {!Empty(user) && account.options.fitness && navigation.view === 'fitness' &&
                        <div title={locale.header.a} className="cs-header-navigation-container">
                            <FontAwesomeIcon className="cs-header-navigation-disabled" icon={faWatchSmart} />
                        </div>
                    }
                    {!Empty(user) && account.options.fitness && navigation.view !== 'fitness' &&
                        <div title={locale.header.a} className="cs-header-navigation-container" onClick={() => {Navigation({view: 'fitness'})}}>
                            <FontAwesomeIcon className="cs-header-navigation-fitness" icon={faWatchSmart} />
                        </div>
                    }
                    {!Empty(user) && navigation.view === 'users' &&
                        <div title={locale.header.g} className="cs-header-navigation-container">
                            <FontAwesomeIcon className="cs-header-navigation-disabled" icon={faUsers} />
                        </div>
                    }
                    {!Empty(user) && navigation.view !== 'users' &&
                        <div title={locale.header.g} className="cs-header-navigation-container" onClick={() => {Navigation({view: 'users', tab: 'users'})}}>
                            <FontAwesomeIcon className="cs-header-navigation-users" icon={faUsers} />
                        </div>
                    }
                    {!Empty(user) &&
                        <div className="cs-header-navigation-divider">|</div>
                    }
                    {!Empty(user) && menu &&
                        <div title={locale.header.f} className="cs-header-navigation-container" onClick={() => {onPopup({view: 'help'})}}>
                            <FontAwesomeIcon className="cs-header-navigation-help" icon={faMessageQuestion} />
                        </div>
                    }
                    {!Empty(user) && user.options.owner && menu &&
                        <div title={locale.header.e} className="cs-header-navigation-container" onClick={() => {onPopup({view: 'account'})}}>
                            <FontAwesomeIcon className="cs-header-navigation-settings" icon={faSquareSlidersVertical} />
                        </div>
                    }
                    {!Empty(user) && menu &&
                        <div title={locale.header.b} className="cs-header-navigation-container tooltip" onClick={() => {Navigation({view:'user'})}}>
                            <FontAwesomeIcon className="cs-header-navigation-user" icon={faSquareUser} />
                        </div>
                    }
                    {!Empty(user) && menu &&
                        <div title={locale.header.d} className="cs-header-navigation-container" onClick={() => {Logout()}}>
                            <FontAwesomeIcon className="cs-header-navigation-logout" icon={faRightFromBracket} />
                        </div>
                    }
                    {!Empty(locale) && menu &&
                        <div title={locale.header.c} className="cs-header-navigation-container" onClick={() => {onPopup({view: 'locale'})}}>
                            <FontAwesomeIcon className="cs-header-navigation-language" icon={faLanguage} />
                        </div>
                    }
                    {!Empty(user) &&
                        <div className="cs-header-navigation-container" onClick={() => {Menu()}}>
                            <FontAwesomeIcon className="cs-header-navigation-disabled" icon={faBars} />
                        </div>
                    }
                    {Empty(user) && !Empty(locale) &&
                        <div title={locale.header.c} className="cs-header-navigation-container" onClick={() => {onPopup({view: 'locale'})}}>
                            <FontAwesomeIcon className="cs-header-navigation-language" icon={faLanguage} />
                        </div>
                    }
                </div>
            </div>
            {!Empty(locale) && popup.view === 'locale' &&
                <HeaderLocale
                    popup={popup}
                    onPopup={onPopup}
                    configuration={configuration}
                    Locale={Locale}
                    language={language}
                />
            }
            {!Empty(locale) && popup.view === 'help' &&
                <HeaderHelp
                    locale={locale}
                    popup={popup}
                    onPopup={onPopup}
                    setMessage={setMessage}
                    response={response}
                    setResponse={setResponse}
                />
            }
            {!Empty(locale) && popup.view === 'account' &&
                <HeaderAccount
                    locale={locale}
                    popup={popup}
                    onPopup={onPopup}
                    setMessage={setMessage}
                    response={response}
                    setResponse={setResponse}
                    account={account}
                    user={user}
                    configuration={configuration}
                    setConfiguration={setConfiguration}
                    navigation={navigation}
                    devices={devices}
                    setDevices={setDevices}
                />
            }
        </div>
    );
}

export default Header;
