import React, {useCallback, useState} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import '../styles/usersGroupNew.css';

function UsersGroupNew({locale, popup, onPopup, setMessage}) {
  const [code, setCode] = useState('');
  const [text, setText] = useState('');
  const [type, setType] = useState('goal');

  const closePopup = useCallback(() => {
    onPopup({view: 'close'});
  }, [onPopup]);

  function Code(data) {
    const specialChar = /[^a-zA-Z0-9]/.test(data);

    if (specialChar) {
      mobiscroll.toast({message: locale.usersGroupNew.j, color: 'warning'});
    }

    const output = data.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    setCode(output);
  }

  function Save() {
    if (!code) {
      mobiscroll.toast({message: locale.usersGroupNew.h, color: 'warning'});
    } else {
      mobiscroll.toast({message: locale.usersGroupNew.i, duration: false, color: 'primary'});

      let item = {};
      item.code = code;
      item.text = text;
      item.type = type;

      let message = {}
      message.type = 'wss';
      message.path = 'groupNew';
      message.action = 'post';
      message.data = item;
      setMessage(message);
    }
  }

  return (
      <Popup className="cs-users-group-new-popup" theme="ios" themeVariant="light" display="center" scrollLock={false} fullScreen={true} headerText={locale.usersGroupNew.a} buttons={[{text: locale.usersGroupNew.b, cssClass: 'cs-users-group-new-popup-save', handler: function (event) {Save()}}]} isOpen={popup.view === 'groupNew'} onClose={closePopup}>
        <mobiscroll.Form>
          <mobiscroll.FormGroup inset className="cs-users-group-new-error">
            <mobiscroll.Input className="cs-users-group-new-error" errorMessage={locale.usersGroupNew.k} labelStyle="floating" type="text" name="Code" placeholder={locale.usersGroupNew.c} value={code} onChange={(ev) => {Code(ev.target.value)}}>
              {locale.usersGroupNew.c}
            </mobiscroll.Input>
          </mobiscroll.FormGroup>
          <mobiscroll.FormGroup inset className="cs-users-group-new-error">
            <mobiscroll.Input labelStyle="floating" type="text" name="Description" placeholder={locale.usersGroupNew.d} value={text} onChange={(ev) => {setText(ev.target.value)}}>
              {locale.usersGroupNew.d}
            </mobiscroll.Input>
            <mobiscroll.Dropdown errorMessage={locale.usersGroupNew.l} labelStyle="floating" label={locale.usersGroupNew.e} value={type} onChange={function (event, inst) {setType(event.target.value)}}>
              <option value="goal">{locale.usersGroupNew.f}</option>
              <option value="device">{locale.usersGroupNew.g}</option>
            </mobiscroll.Dropdown>
          </mobiscroll.FormGroup>
        </mobiscroll.Form>
      </Popup>
  );
}

export default UsersGroupNew;
