import React, {useCallback, useState, useEffect} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import '../styles/loginHelp.css';
import Empty from "../utils/Empty";

function HeaderHelp({locale, account, popup, onPopup, setMessage, response, setResponse}) {
  const [text, setText] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const closePopup = useCallback(() => {
    onPopup({view: 'close'});
  }, [onPopup]);

  function Help() {
    if (!text && !email && !name) {
      mobiscroll.toast({message: locale.loginHelp.a, color: 'danger'});
    } else {
      mobiscroll.notification.dismiss();
      mobiscroll.toast({message: locale.loginHelp.b, duration: false, color: 'info'});

      let message = {}
      message.type = 'rest';
      message.path = 'loginHelp';
      message.action = 'post';
      message.data = {};
      message.data.text = text;
      message.data.email = email;
      message.data.name = name;
      message.data.account = account.name;

      setMessage(message);
    }
  }

  function POSTHelp(data) {
    if (data.toast) {
      closePopup();
      mobiscroll.toast({message: locale.loginHelp.c, color: 'info'});
    }
  }

  useEffect(() => {
    if (!Empty(response) && response.action === 'post' && response.path === 'loginHelp') {
      POSTHelp(response.data);
      setResponse({});
    }
  }, [response, setResponse])

  return (
      <Popup theme="ios" themeVariant="light" display="center" scrollLock={false} fullScreen={true} maxHeight="700" headerText={locale.loginHelp.d} buttons={[{text: locale.loginHelp.e, cssClass: 'cs-login-help-popup-send', handler: function (event) {Help()}}]} isOpen={popup.view === 'loginHelp'} onClose={closePopup}>
        <div className="cs-login-help-helper">
          <p>{locale.loginHelp.l}</p>
        </div>
        <div className="cs-login-help-helper">
          <p>{locale.loginHelp.m}</p>
        </div>
        <div className="cs-login-help-helper">
          <p>{locale.loginHelp.n}</p>
        </div>
        <mobiscroll.Form className="cs-login-help-text">
          <mobiscroll.FormGroup inset>
            <mobiscroll.Input labelStyle="floating" type="text" name="Name" placeholder={locale.loginHelp.f} value={name} onChange={(ev) => {setName(ev.target.value)}}>
              {locale.loginHelp.g}
            </mobiscroll.Input>
            <mobiscroll.Input labelStyle="floating" type="text" name="Email" placeholder={locale.loginHelp.h} value={email} onChange={(ev) => {setEmail(ev.target.value)}}>
              {locale.loginHelp.i}
            </mobiscroll.Input>
          </mobiscroll.FormGroup>
          <mobiscroll.FormGroup inset>
            <mobiscroll.Textarea labelStyle="floating" placeholder={locale.loginHelp.j} value={text} onChange={(ev) => {setText(ev.target.value)}}>
              {locale.loginHelp.k}
            </mobiscroll.Textarea>
          </mobiscroll.FormGroup>
        </mobiscroll.Form>
      </Popup>
  );
}

export default HeaderHelp;
