import React, {useEffect} from 'react';
import axios from 'axios';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import Empty from './utils/Empty';
import Decode from './utils/Decode';

const REST = props => {
    const sendRequest = (method, data) => {
        const item = data;
        const url = Decode(props.rest);
        item.token = props.token;
        item.session = props.session;

        axios({
            method: method,
            url: url,
            [method === 'GET' ? 'params' : 'data']: item,
        }).then(response => {
            props.setResponse(response.data);
        });
        props.setMessage({});
    };

    useEffect(() => {
        if (!Empty(props.message) && props.message.type === 'rest') {
            const {action} = props.message;
            if (action === 'get' || action === 'post' || action === 'delete') {
                sendRequest(action.toUpperCase(), props.message);
            }
        }
    }, [props.message]);

    return (
        <></>
    );
};

export default REST;
