import React, {useState , useEffect, useRef, useCallback} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import './styles/app.css';
import Login from './Login';
import Register from './Register';
import Header from './Header';
import Queue from './Queue';
import Fitness from './Fitness';
import Users from './Users';
import REST from "./REST";
import Websocket from "./Websocket";
import Empty from './utils/Empty';
mobiscroll.settings = {
  theme: 'ios',
  themeVariant: 'light'
}
function Session() {
  let data = {};
  data.navigator = {};
  data.navigator.agent = navigator.userAgent;
  data.navigator.language = navigator.language;
  data.navigator.referrer = document.referrer;
  data.id = Math.floor(Math.random() * 1000000000000);
  data.version = "2.0";
  data.app = "eyJhbGciOiJIUzI1NiJ9.U21hcnREb2NIZWFsdGg.bH6kzmYF7ssmV8Oi_m1TOiN4QysKS-AxVCunqIeV8Lo";

  return data;
}

function App() {
  const token = useRef('');
  const session = useRef(Session());
  const rest = useRef('aHR0cHM6Ly9jb25uZWN0LnNtYXJ0ZG9jaGVhbHRoLmNvbQ==');
  const [width, setWidth] = useState(0);
  const [wss, setWss] = useState('');
  const [account, setAccount] = useState({});
  const [invite, setInvite] = useState('');
  const [message, setMessage] = useState({});
  const [response, setResponse] = useState({});
  const [user, setUser] = useState({});
  const [configuration, setConfiguration] = useState({});
  const [locale, setLocale] = useState({});
  const [language, setLanguage] = useState('en');
  const [devices, setDevices] = useState([]);
  const [navigation, setNavigation] = useState({view: 'login'});
  const [popup, setPopup] = useState({});
  const [reset, setReset] = useState('');
  const [urlSelect, setUrlSelect] = useState(false);
  const [urlLocale, setUrlLocale] = useState('');

  const updateWidth = () => {
    const width = window.innerWidth;
    setWidth(width);
  }

  const Token = data => {
    token.current = data;
  }

  const Locale = (data) => {
    if (data === 'es') {
      setLocale(configuration.locale.es);
      setLanguage('es');
      window.localStorage.locale = 'es';
    } else if (data === 'en') {
      setLocale(configuration.locale.en);
      setLanguage('en');
      window.localStorage.locale = 'en';
    } else if (data === 'fr') {
      setLocale(configuration.locale.fr);
      setLanguage('fr');
      window.localStorage.locale = 'fr';
    } else {
      data = 'en';
      setLocale(configuration.locale.en);
      setLanguage('en');
      window.localStorage.locale = 'en';
    }
    if (!Empty(user) && user.locale !== data) {
      mobiscroll.notification.dismiss();
      mobiscroll.toast({message: 'Saving configuration', duration: false, color: 'primary'});
      let item = user;
      item.locale = data;
      setUser(item);
      setLanguage(data);

      let message = {};
      message.type = 'rest';
      message.path = 'locale';
      message.action = 'post';
      message.data = {
        locale: data
      };
      setMessage(message);
    }
  }

  const Account = () => {
    if (navigation.view === 'login') {
      mobiscroll.confirm({
        title: locale.app.a,
        message: locale.app.b,
        okText: locale.app.c,
        cancelText: locale.app.d,
        callback: (res) => {
          if (res) {
            window.localStorage.removeItem('account');
            setAccount({});
            Configuration('');
          }
        }
      });
    }
  }

  const Configuration = useCallback(data => {
    let message = {};
    message.type = 'rest';
    message.path = 'configuration';
    message.action = 'get';
    message.data = {};
    if (reset) {
      message.data.reset = reset;
    }
    if (data) {
      message.data.account = data;
    }
    setMessage(message);
  }, [reset]);

  const Start = useCallback(() => {
    let message = {};
    message.type = 'wss';
    message.path = 'start';
    message.action = 'get';
    message.data = {};
    setMessage(message);
  }, []);

  const Navigation = useCallback(data => {
    let update = data;
    let current = navigation;
    let previous = {
      view: '',
      tab: '',
      item: '',
      metric: ''
    };
    let view = '';
    let tab = '';
    let item = '';
    let metric = '';

    if (update.view) {
      view = update.view;
      previous.view = current.view;
      if (current.tab) {
        previous.tab = current.tab;
      }
      if (current.item) {
        previous.item = current.item;
      }
      if (current.metric) {
        previous.metric = current.metric;
      }
      if (update.tab) {
        tab = update.tab;
      } else if (update.view === current.previous.view && current.previous.tab){
        tab = current.previous.tab;
      }
      if (update.item) {
        item = update.item;
      } else if (update.view === current.previous.view && current.previous.item){
        item = current.previous.item;
      }
      if (update.metric) {
        metric = update.metric;
      } else if (update.view === current.previous.view && current.previous.metric){
        metric = current.previous.metric;
      }
    } else if (update.tab || update.item || update.metric) {
      view = current.view;

      if (update.tab) {
        tab = update.tab;
      } else if (current.tab) {
        tab = current.tab;
      }
      if (update.item) {
        item = update.item;
      } else if (current.item) {
        item = current.item;
      }
      if (update.metric) {
        metric = update.metric;
      } else if (current.metric) {
        metric = current.metric;
      }

      previous = current.previous;
    }
    let object = {
      view: view,
      tab: tab,
      item: item,
      metric: metric,
      previous: previous
    }
    setNavigation(object);
  }, [navigation]);

  const Popup = useCallback(data => {
    let update = data;
    let current = popup;

    let view = '';
    let tab = '';

    if (update.view && update.view === 'close') {
      view = '';
      tab = '';
    } else if (update.view || update.tab) {
      view = current.view;

      if (update.view) {
        view = update.view;
      } else if (current.view) {
        view = current.view;
      }
      if (update.tab) {
        tab = update.tab;
      } else if (current.tab) {
        tab = current.tab;
      }
    }
    let object = {
      view: view,
      tab: tab
    }
    setPopup(object);
  }, [popup]);

  const Logout = useCallback(() => {
    window.localStorage.removeItem('token');
    token.current = '';
    setWss('');
    setUser({});
    setConfiguration({});
    setReset('');
    setNavigation({});
    setPopup({});
    setResponse({});
    let pathname = window.localStorage.account;
    Configuration(pathname);
    document.body.style.background = "linear-gradient(36deg, #263CAE, #0FBEDB)";

    if (!Empty(locale) && locale.app && locale.app.f) {
      mobiscroll.toast({message: locale.app.f, color: 'warning'});
    }
  }, [Configuration, locale]);

  const GETConfiguration = useCallback(data => {
    if (data.configuration) {
      setConfiguration(data.configuration);
    }
    if (data.account) {
      setAccount(data.account);
      window.localStorage.account = data.account.name;
    }
    if (data.url) {
      setWss(data.url);
    }
    if (data.popup) {
      setPopup(data.popup);
    }
    if (data.navigation) {
      setNavigation(data.navigation);
    }
    if (data.expired) {
      Logout();
    }
    if (data.error) {
      mobiscroll.toast({message: "We can't find that account", color: 'warning', display: 'top'});
    }
    mobiscroll.notification.dismiss();
  }, [Logout]);

  const GETStart = useCallback(data => {
    if (data.user) {
      setUser(data.user);
      Locale(data.user.locale);
      if (account.options.triage || account.options.scheduling) {
        Navigation({view: 'queue'});
      } else if (account.options.fitness) {
        Navigation({view: 'fitness'});
      }
    }
    if (data.account) {
      setAccount(data.account);
    }
    if (data.mobiUsers) {
      let item = configuration;
      item.mobi.users = data.mobiUsers;
      setConfiguration(item);
    }
    mobiscroll.notification.dismiss();
  }, [Navigation, Locale]);

  const POSTLocale = useCallback(data => {
    if (data.toast) {
      mobiscroll.toast({message: locale.app.e, color: 'primary'});
    }
    mobiscroll.notification.dismiss();
  }, [locale.app]);

  useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'configuration') {
          GETConfiguration(response.data);
          setResponse({});
        }
      },
      [response, GETConfiguration],
  );

  useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'start') {
          GETStart(response.data);
          setResponse({});
        }
      },
      [response, GETStart],
  );

  useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'locale') {
          POSTLocale(response.data);
          setResponse({});
        }
      },
      [response, POSTLocale],
  );

  useEffect(() => {
        if (wss) {
          mobiscroll.notification.dismiss();
          mobiscroll.toast({message: 'Starting application', duration: false, color: 'primary'});
          Start();
        }
      },
      [wss, Start],
  );

  useEffect(() => {
        if (urlLocale) {
          Locale(urlLocale);
          setUrlLocale('');
        } else if (!Empty(configuration) && window.localStorage.locale && !urlLocale) {
          let data = window.localStorage.locale;
          Locale(data);
        } else if (!Empty(configuration) && !window.localStorage.locale && !urlLocale) {
          Locale('en');
        }
      },
      [configuration],
  );

  useEffect(() => {
        token.current = window.localStorage.token;
        let location = window.location.href;
        const url = new URL(location);

        let pathname = window.localStorage.account;
        if (url.pathname.length > 1) {
          pathname = url.pathname.substring(1);
        }
        let itemLocale = url.searchParams.get('locale');
        if (itemLocale) {
          setUrlLocale(itemLocale);
        }
        let itemSelect = url.searchParams.get('select');
        if (itemSelect) {
          setUrlSelect(true);
        }
        let itemToken = url.searchParams.get('token');
        if (itemToken) {
          setReset(itemToken);
        }
        let itemInvite = url.searchParams.get('invite');
        if (itemInvite) {
          setInvite(itemInvite);
        }

        window.history.replaceState(null, "SmartDocHealth", "/");
        document.title = 'SmartDocHealth';

        mobiscroll.toast({message: 'Getting configuration', duration: false, color: 'primary'});
        Configuration(pathname);

        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
      },
      [],
  );

  return (
      <mobiscroll.Page theme="ios" themeVariant="light">
        {!Empty(configuration) &&
            <Header
                Locale={Locale}
                navigation={navigation}
                Navigation={Navigation}
                Account={Account}
                account={account}
                user={user}
                onPopup={Popup}
                popup={popup}
                configuration={configuration}
                setConfiguration={setConfiguration}
                language={language}
                width={width}
                locale={locale}
                Logout={Logout}
                setMessage={setMessage}
                response={response}
                setResponse={setResponse}
                devices={devices}
                setDevices={setDevices}
            />
        }
        {Empty(user) && !Empty(locale) && navigation.view === 'login' &&
            <Login
                Token={Token}
                setWss={setWss}
                locale={locale}
                response={response}
                setResponse={setResponse}
                setMessage={setMessage}
                Navigation={Navigation}
                popup={popup}
                onPopup={Popup}
                reset={reset}
                setReset={setReset}
                account={account}
                width={width}
                urlSelect={urlSelect}
                setUrlSelect={setUrlSelect}
            />
        }
        {Empty(user) && navigation.view === 'register' &&
            <Register
                locale={locale}
                response={response}
                setResponse={setResponse}
                setMessage={setMessage}
                Navigation={Navigation}
                account={account}
                invite={invite}
            />
        }
        {!Empty(user) && navigation.view === 'queue' &&
            <Queue
                locale={locale}
                setMessage={setMessage}
                response={response}
                setResponse={setResponse}
                navigation={navigation}
                Navigation={Navigation}
                user={user}
            />
        }
        {!Empty(user) && navigation.view === 'fitness' &&
            <Fitness
                locale={locale}
                setMessage={setMessage}
                response={response}
                setResponse={setResponse}
                onPopup={Popup}
                popup={popup}
                configuration={configuration}
                setConfiguration={setConfiguration}
                user={user}
                devices={devices}
                setDevices={setDevices}
            />
        }
        {!Empty(user) && navigation.view === 'users' &&
            <Users
                locale={locale}
                setMessage={setMessage}
                response={response}
                setResponse={setResponse}
                onPopup={Popup}
                popup={popup}
                configuration={configuration}
                setConfiguration={setConfiguration}
                user={user}
                Navigation={Navigation}
                navigation={navigation}
                account={account}
            />
        }
        {wss && !Empty(locale) &&
            <Websocket
                wss={wss}
                locale={locale}
                token={token.current}
                session={session.current}
                message={message}
                setMessage={setMessage}
                setResponse={setResponse}
                Logout={Logout}
            />
        }
        <REST
            rest={rest.current}
            token={token.current}
            session={session.current}
            message={message}
            setMessage={setMessage}
            setResponse={setResponse}
        />
      </mobiscroll.Page>
  );
}

export default App;
