import React, {useCallback, useEffect, useState} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import dayjs from 'dayjs';
import '../styles/usersGroup.css';
import Empty from "../utils/Empty";

function UsersGroup({groupData, locale, popup, onPopup, setMessage, setGroupData, configuration, response, setResponse}) {
  const [toggle, setToggle] = useState('');
  const [text, setText] = useState(groupData.text);
  const [privateGroup, setPrivateGroup] = useState(groupData.private);
  const [goalAnon, setGoalAnon] = useState(groupData.goal.anon);
  const [goalDisplay, setGoalDisplay] = useState(groupData.goal.display);
  const [goalMetric, setGoalMetric] = useState(groupData.goal.metric);
  const [goalNickname, setGoalNickname] = useState(groupData.goal.nickname);
  const [goalTarget, setGoalTarget] = useState(groupData.goal.target);
  const [goalType, setGoalType] = useState(groupData.goal.type);
  const [tab, setTab] = useState('group');
  const [tabRange, setTabRange] = useState('day');
  const [leaderboard, setLeaderboard] = useState([]);
  const [toDelete, setToDelete] = useState(null);

  const closePopup = useCallback(() => {
    setGroupData({});
    onPopup({view: 'close'});
  }, [onPopup, setGroupData]);

  function Save() {
    mobiscroll.toast({message: locale.usersGroup.a, duration: false, color: 'primary'});

    let item = {};
    item.group = groupData.code;
    item.text = text;
    item.private = privateGroup;
    item.goal = {};
    item.goal.anon = goalAnon;
    item.goal.display = goalDisplay;
    item.goal.metric = goalMetric;
    item.goal.nickname = goalNickname;
    item.goal.target = goalTarget;
    item.goal.type = goalType;

    let message = {}
    message.type = 'wss';
    message.path = 'group';
    message.action = 'post';
    message.data = item;
    setMessage(message);
  }

  function UserPost(data) {
    mobiscroll.toast({message: locale.usersGroup.a, duration: false, color: 'primary'});

    let message = {}
    message.type = 'wss';
    message.path = 'groupUser';
    message.action = 'post';
    message.data = {
      user: data,
      group: groupData.code
    };
    setMessage(message);
  }

  function UserDelete(data) {
    mobiscroll.toast({message: locale.usersGroup.a, duration: false, color: 'primary'});

    let message = {}
    message.type = 'wss';
    message.path = 'groupUser';
    message.action = 'delete';
    message.data = {
      user: data,
      group: groupData.code
    };
    setMessage(message);
  }

  function Delete() {
    mobiscroll.confirm({
      title: locale.usersGroup.v,
      message: locale.usersGroup.w,
      okText: locale.usersGroup.c,
      cancelText: locale.usersGroup.al,
      callback: (res) => {
        if (res) {
          let message = {};
          message.type = 'wss';
          message.path = 'group';
          message.action = 'delete';
          message.data = {
            group: groupData.code
          };
          setMessage(message);
        }
      }
    });
  }

  function Leaderboard() {
    mobiscroll.toast({message: locale.usersGroup.ad, color: 'primary', display: 'top'});

    let date = dayjs().format('YYYY-M-D');

    let message = {}
    message.type = 'wss';
    message.path = 'leaderboard';
    message.action = 'get';
    message.data = {
      group: groupData.code,
      range: tabRange,
      date: date
    };
    setMessage(message);
  }

  function LeaderboardItem(data) {
    return <li>
      <div className="mbsc-row mbsc-justify-content-between">
        <div className="mbsc-row cs-users-group-leaderboard-left">
          <div className="cs-users-group-leaderboard-avatar">
            <mobiscroll.Avatar src={'https://www.gravatar.com/avatar/' + data.item.avatar + '?d=retro'} />
          </div>
          <div className="mbsc-row cs-users-group-leaderboard-title">
            <div>
              {data.item.firstName && data.item.lastName && data.item.email ?
                  <h4 className="cs-users-group-leaderboard-title-text">{data.item.firstName + ' ' + data.item.lastName + '  |  ' + data.item.email}</h4>
                  :
                  <>
                    {data.item.nickname ?
                        <h4 className="cs-users-group-leaderboard-title-text">{data.item.nickname}</h4>
                        :
                        <h4 className="cs-users-group-leaderboard-title-text">{locale.home.ay}</h4>
                    }
                  </>
              }
              <p className="cs-users-group-leaderboard-title-text">{data.item.moves} moves | {data.item.kcalories} {locale.home.h} | {data.item.steps} {locale.home.j}</p>
            </div>
          </div>
        </div>
        <div className="mbsc-row cs-users-group-leaderboard-right">
          <mobiscroll.Button flat={true} color="info">{data.item.percentage}%</mobiscroll.Button>
          <mobiscroll.Button flat={true} color="info">{data.item.rank}</mobiscroll.Button>
        </div>
      </div>
    </li>;
  }

  const POSTGroupUser = useCallback(data => {
    if (data.toast) {
      mobiscroll.toast({message: locale.usersGroup.ac, color: 'primary'});
      if (tabRange === 'day') {
        Leaderboard();
      } else {
        setTabRange('day');
      }
    }
    if (data.error) {
      mobiscroll.toast({message: locale.usersGroup.ab, color: 'warning'});
    }
  }, [locale.users.i]);

  const DELETEGroupUser = useCallback(data => {
    if (data.toast) {
      mobiscroll.toast({message: locale.usersGroup.ac, color: 'primary'});
    }
    if (data.error) {
      mobiscroll.toast({message: locale.usersGroup.ab, color: 'warning'});
      Leaderboard();
    }
  }, [locale.users.i]);

  const GETLeaderboard = useCallback(data => {
    if (data.leaderboard) {
      setLeaderboard(data.leaderboard);
      mobiscroll.notification.dismiss();
    }
    if (data.error) {
      mobiscroll.toast({message: locale.usersGroup.ab, color: 'warning'});
    }
  }, [locale.users.i]);

  useEffect(() => {
    if (!Empty(response) && response.action === 'post' && response.path === 'groupUser') {
      POSTGroupUser(response.data);
      setResponse({});
    }
  }, [response, POSTGroupUser, setResponse])

  useEffect(() => {
    if (!Empty(response) && response.action === 'delete' && response.path === 'groupUser') {
      DELETEGroupUser(response.data);
      setResponse({});
    }
  }, [response, DELETEGroupUser, setResponse])

  useEffect(() => {
    if (!Empty(response) && response.action === 'get' && response.path === 'leaderboard') {
      GETLeaderboard(response.data);
      setResponse({});
    }
  }, [response, GETLeaderboard, setResponse])

  useEffect(() => {
    if (tab === 'leaderboard' && tabRange === 'day') {
      Leaderboard();
    } else {
      setTabRange('day');
    }
  }, [tab]);

  useEffect(() => {
    if (tabRange) {
      Leaderboard();
    }
  }, [tabRange]);

  useEffect(() => {
    if (toDelete || toDelete === 0) {
      let item = leaderboard[toDelete].email;
      UserDelete(item);
      let array = leaderboard;
      array.splice(toDelete, 1);
      setLeaderboard(array);
      setToDelete(null);
    }
  }, [toDelete]);

  useEffect(() => {
        if (toggle === 'anon' && goalAnon) {
          setGoalAnon(false);
        } else if (toggle === 'anon' && !goalAnon) {
          setGoalAnon(true);
        }
        if (toggle === 'display' && goalDisplay) {
          setGoalDisplay(false);
        } else if (toggle === 'display' && !goalDisplay) {
          setGoalDisplay(true);
        }
        if (toggle === 'nickname' && goalNickname) {
          setGoalNickname(false);
        } else if (toggle === 'nickname' && !goalNickname) {
          setGoalNickname(true);
        }
        if (toggle === 'private' && privateGroup) {
          setPrivateGroup(false);
        } else if (toggle === 'private' && !privateGroup) {
          setPrivateGroup(true);
        }
        if (toggle) {
          setToggle('')
        }
      },
      [toggle],
  );

  return (
      <Popup className="cs-users-group-popup" theme="ios" themeVariant="light" display="center" scrollLock={false} fullScreen={true} maxHeight="700" headerText={groupData.code} buttons={[{text: locale.usersGroup.b, cssClass: 'cs-users-group-popup-save', handler: function (event) {Save()}}, {text: locale.usersGroup.c, cssClass: 'cs-users-group-popup-delete', handler: function (event) {Delete()}}]} isOpen={popup.view === 'group'} onClose={closePopup}>
        <mobiscroll.TabNav className="cs-users-group-nav" display="inline">
          <mobiscroll.NavItem selected={tab === 'group'} onClick={(ev) => {setTab('group')}} ><mobiscroll.Button color="primary" className="cs-users-group-nav-button" flat={tab === 'group'}>{locale.usersGroup.d}</mobiscroll.Button></mobiscroll.NavItem>
          {groupData.type === "goal" &&
            <mobiscroll.NavItem selected={tab === 'goal'} onClick={(ev) => {setTab('goal')}} ><mobiscroll.Button color="success" className="cs-users-group-nav-button" flat={tab === 'goal'}>{locale.usersGroup.e}</mobiscroll.Button></mobiscroll.NavItem>
          }
          {groupData.type === "goal" &&
              <mobiscroll.NavItem selected={tab === 'leaderboard'} onClick={(ev) => {setTab('leaderboard')}}><mobiscroll.Button color="warning" className="cs-users-group-nav-button" flat={tab === 'leaderboard'}>{locale.usersGroup.f}</mobiscroll.Button></mobiscroll.NavItem>
          }
        </mobiscroll.TabNav>
        <mobiscroll.Form>
          {tab === 'group' &&
              <mobiscroll.FormGroup inset>
                <mobiscroll.Input labelStyle="floating" type="text" name="Description" placeholder={locale.usersGroup.h} value={text} onChange={(ev) => {setText(ev.target.value)}}>
                  {locale.usersGroup.h}
                </mobiscroll.Input>
                <mobiscroll.Dropdown disabled labelStyle="floating" label={locale.usersGroup.g} value={groupData.type}>
                  <option value="goal">{locale.usersGroup.e}</option>
                  <option value="device">{locale.usersGroup.i}</option>
                </mobiscroll.Dropdown>
                <mobiscroll.Switch checked={privateGroup} onChange={(ev) => setToggle('private')}>
                  {locale.usersGroup.j}
                  <span className="mbsc-desc">{locale.usersGroup.ah}</span>
                </mobiscroll.Switch>
              </mobiscroll.FormGroup>
          }
          {tab === 'goal' &&
              <mobiscroll.FormGroup inset>
                <mobiscroll.Switch checked={goalAnon} onChange={(ev) => setToggle('anon')}>
                  {locale.usersGroup.k}
                  <span className="mbsc-desc">{locale.usersGroup.ai}</span>
                </mobiscroll.Switch>
                <mobiscroll.Switch checked={goalDisplay} onChange={(ev) => setToggle('display')}>
                  {locale.usersGroup.l}
                  <span className="mbsc-desc">{locale.usersGroup.aj}</span>
                </mobiscroll.Switch>
                <mobiscroll.Switch checked={goalNickname} onChange={(ev) => setToggle('nickname')}>
                  {locale.usersGroup.p}
                  <span className="mbsc-desc">{locale.usersGroup.ak}</span>
                </mobiscroll.Switch>
                <mobiscroll.Dropdown labelStyle="floating" label={locale.usersGroup.m} value={goalMetric} onChange={function (event, inst) {setGoalMetric(event.target.value)}}>
                  <option value="steps">{locale.usersGroup.n}</option>
                  <option value="calories">{locale.usersGroup.o}</option>
                </mobiscroll.Dropdown>
                <mobiscroll.Input labelStyle="floating" type="text" name="Target" placeholder={locale.usersGroup.q} value={goalTarget} onChange={(ev) => {setGoalTarget(ev.target.value)}}>
                  {locale.usersGroup.q}
                </mobiscroll.Input>
                <mobiscroll.Dropdown labelStyle="floating" label={locale.usersGroup.r} value={goalType} onChange={function (event, inst) {setGoalType(event.target.value)}}>
                  <option value="daily">{locale.usersGroup.s}</option>
                  <option value="weekly">{locale.usersGroup.t}</option>
                  <option value="monthly">{locale.usersGroup.u}</option>
                </mobiscroll.Dropdown>
              </mobiscroll.FormGroup>
          }
          {tab === 'leaderboard' &&
              <mobiscroll.FormGroup inset>
                {configuration.mobi.users.length !== 0 &&
                    <mobiscroll.Select display="center" select='single' filter={true} touchUi={false} onSet={function(event, inst) {UserPost(inst.getVal()); inst.clear();}} data={configuration.mobi.users} buttons={[]} >
                      <mobiscroll.Input>{locale.usersGroup.aa}</mobiscroll.Input>
                    </mobiscroll.Select>
                }
                {configuration.mobi.users.length === 0 &&
                    <div className="mbsc-row mbsc-justify-content-center">
                      <mobiscroll.Button disabled flat={true}>{locale.usersGroup.y}</mobiscroll.Button>
                    </div>
                }
              </mobiscroll.FormGroup>
          }
          {tab === 'leaderboard' &&
              <mobiscroll.TabNav className="cs-users-group-nav" display="inline">
                <mobiscroll.NavItem selected={tabRange === 'day'} onClick={(ev) => {setTabRange('day')}} ><mobiscroll.Button color="success" className="cs-users-group-nav-button" flat={tabRange === 'day'}>{locale.usersGroup.ae}</mobiscroll.Button></mobiscroll.NavItem>
                <mobiscroll.NavItem selected={tabRange === 'week'} onClick={(ev) => {setTabRange('week')}} ><mobiscroll.Button color="warning" className="cs-users-group-nav-button" flat={tabRange === 'week'}>{locale.usersGroup.af}</mobiscroll.Button></mobiscroll.NavItem>
                <mobiscroll.NavItem selected={tabRange === 'month'} onClick={(ev) => {setTabRange('month')}}><mobiscroll.Button color="info" className="cs-users-group-nav-button" flat={tabRange === 'month'}>{locale.usersGroup.ag}</mobiscroll.Button></mobiscroll.NavItem>
              </mobiscroll.TabNav>
          }
          {tab === 'leaderboard' && leaderboard.length !== 0 &&
              <mobiscroll.Listview
                  striped={true}
                  itemType={LeaderboardItem}
                  stages={{
                    right: [{
                      icon: 'remove',
                      text: 'Remove',
                      confirm: true,
                      color: '#E97178',
                      action: (event, inst) => {
                        inst.remove(event.target, null, () => {
                          setToDelete(event.index);
                        });
                        return false;
                      }
                    }]
                  }}
                  data={leaderboard}
              />
          }
        </mobiscroll.Form>
      </Popup>
  );
}

export default UsersGroup;
