import React, {useCallback, useState} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import Mobile from '../utils/Mobile';
import '../styles/usersUserNew.css';

function UsersUserNew({locale, popup, onPopup, setMessage}) {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const closePopup = useCallback(() => {
    onPopup({view: 'close'});
  }, [onPopup]);

  function Save() {
    if (password && password !== confirm) {
      mobiscroll.toast({message: locale.usersUserNew.j, color: 'warning'});
    } else if (!email) {
      mobiscroll.toast({message: locale.usersUserNew.k, color: 'warning'});
    } else {
      mobiscroll.toast({message: locale.usersUserNew.l, duration: false, color: 'primary'});
      let number = '';
      if (mobile) {
        number = mobile.replace(/[()\s-]/g, '');
      }

      let item = {};
      item.firstName = firstName;
      item.lastName = lastName;
      item.email = email;
      item.mobile = number;
      item.password = password;

      let message = {}
      message.type = 'wss';
      message.path = 'userNew';
      message.action = 'post';
      message.data = item;
      setMessage(message);
    }
  }

  return (
      <Popup className="cs-users-user-new-popup" theme="ios" themeVariant="light" display="center" scrollLock={false} fullScreen={true} headerText={locale.usersUserNew.a} buttons={[{text: locale.usersUserNew.b, cssClass: 'cs-users-user-new-popup-save', handler: function (event) {Save()}}]} isOpen={popup.view === 'userNew'} onClose={closePopup}>
        <mobiscroll.Form>
          <mobiscroll.FormGroup inset>
            <mobiscroll.Input labelStyle="floating" type="email" name="Email" placeholder={locale.usersUserNew.c} value={email} onChange={(ev) => {setEmail(ev.target.value)}}>
              {locale.usersUserNew.c}
            </mobiscroll.Input>
            <mobiscroll.Input labelStyle="floating" type="text" name="First Name" placeholder={locale.usersUserNew.d} value={firstName} onChange={(ev) => {setFirstName(ev.target.value)}}>
              {locale.usersUserNew.d}
            </mobiscroll.Input>
            <mobiscroll.Input labelStyle="floating" type="text" name="Last Name" placeholder={locale.usersUserNew.e} value={lastName} onChange={(ev) => {setLastName(ev.target.value)}}>
              {locale.usersUserNew.e}
            </mobiscroll.Input>
            <mobiscroll.Input labelStyle="floating" type="text" name="Mobile" placeholder={locale.usersUserNew.f} value={mobile} onChange={(ev) => {Mobile({data: ev.target.value, setMobile: setMobile})}}>
              {locale.usersUserNew.f}
            </mobiscroll.Input>
            <mobiscroll.Input labelStyle="floating" minLength="8" type="password" name="Password" placeholder={locale.usersUserNew.h} passwordToggle={true} icon="none" iconAlign="right" value={password} onChange={(ev) => {setPassword(ev.target.value)}}>
              {locale.usersUserNew.h}
            </mobiscroll.Input>
            <mobiscroll.Input labelStyle="floating" minLength="8" type="password" name="Confirm Password" placeholder={locale.usersUserNew.i} passwordToggle={true} icon="none" iconAlign="right" value={confirm} onChange={(ev) => {setConfirm(ev.target.value)}}>
              {locale.usersUserNew.i}
            </mobiscroll.Input>
          </mobiscroll.FormGroup>
        </mobiscroll.Form>
      </Popup>
  );
}

export default UsersUserNew;
