import React, {useEffect, useCallback, useRef, useState} from 'react';
import websocket from "react-use-websocket";
import mobiscroll from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import Empty from './utils/Empty';
import Decode from './utils/Decode';

const Websocket = props => {
    const loading = useRef(true);
    const [state, setState] = useState('open');

    const {sendJsonMessage, lastJsonMessage} = websocket(Decode(props.wss), {
        onOpen: () => setState('open'),
        onClose: () => setState('close'),
        onError: () => setState('error'),
        onMessage: (event) => Update(event.data),
        retryOnError: true,
        reconnectAttempts: 100,
        reconnectInterval: 5000,
        share: true
    });

    const Send = useCallback(data => {
            let item = data;
            item.token = props.token;
            item.session = props.session;

            sendJsonMessage(item);
        },
        [props.token, props.session, sendJsonMessage],
    );

    function Update(data) {
        let message = JSON.parse(data);
        if (message.data?.expired) {
            mobiscroll.toast({message: props.locale.websocket.c, color: 'warning'});
            props.Logout();
        } else {
            props.setResponse(message);
        }
    }

    useEffect(() => {
            if (state === 'open') {
                mobiscroll.toast({message: props.locale.websocket.a, color: 'primary'});
                loading.current = false;
            } else if (state === 'close') {
                mobiscroll.toast({message: props.locale.websocket.b, color: 'warning', duration: false});
            } else if (state === 'error') {
                mobiscroll.toast({message: props.locale.websocket.b, color: 'warning', duration: false});
            }
        },
        [state, props.locale.websocket.b, props.locale.websocket.a],
    );

    useEffect(() => {
            if (!Empty(props.message) && props.message.type === 'wss') {
                Send(props.message);
            }
        },
        [props.message, Send],
    );

    return (
        <></>
    );
}

export default Websocket;
