import React, {useCallback} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import '../styles/headerLocale.css';

function HeaderLocale({popup, onPopup, configuration, Locale, language}) {

  const closePopup = useCallback(() => {
    onPopup({view: 'close'});
  }, [onPopup]);

  const Language = useCallback((data) => {
    closePopup();
    Locale(data);
  }, [Locale, closePopup]);

  return (
      <Popup className="cs-header-popup" theme="ios" themeVariant="light" display="center" buttons={[]} isOpen={popup.view === 'locale'} onClose={closePopup}>
        <mobiscroll.Form>
          <mobiscroll.FormGroup>
            <mobiscroll.Select rows={3} showInput={false} display="inline" touchUi={false} select="single" value={language} data={configuration.mobi.language} buttons={[]} onSet={(event, inst) => {Language(inst.getVal())}} />
          </mobiscroll.FormGroup>
        </mobiscroll.Form>
      </Popup>
  );
}

export default HeaderLocale;
