import React, {useCallback, useState, useEffect} from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import './styles/login.css';
import Empty from './utils/Empty';
import LoginReset from "./blocks/LoginReset";
import LoginSelect from "./blocks/LoginSelect";
import LoginHelp from "./blocks/LoginHelp";

function Login({setWss, Token, account, locale, response, setResponse, Navigation, popup, onPopup, reset, setReset, setMessage, width, urlSelect, setUrlSelect}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [next, setNext] = useState(true);

    function Auth() {
        if (!password) {
            mobiscroll.toast({message: locale.login.h, color: 'danger'});
        } else {
            let message = {};
            message.type = 'rest';
            message.path = 'auth';
            message.action = 'post';
            message.data = {
                email: email,
                password: password,
                account: account.name
            };
            setMessage(message);
        }
    }

    function Forgot() {
        if (!email) {
            mobiscroll.toast({message: locale.login.f, color: 'danger'});
        } else {
            let message = {};
            message.type = 'rest';
            message.path = 'forgot';
            message.action = 'post';
            message.data = {
                email: email,
                account: account.name,
            };
            setMessage(message);
        }
    }

    function Next() {
        if (!email) {
            mobiscroll.toast({message: locale.login.f, color: 'danger'});
        } else {
            let message = {};
            message.type = 'rest';
            message.path = 'username';
            message.action = 'get';
            message.data = {
                email: email,
                account: account.name,
            };
            setMessage(message);
        }
    }

    function Help() {
        onPopup({view: 'loginHelp'});
    }

    const CheckToken = useCallback(() => {
        let message = {};
        message.type = 'rest';
        message.path = 'token';
        message.action = 'get';
        message.data = {
            token: reset
        };
        setMessage(message);
    }, [reset, setMessage]);

    const POSTForgot = useCallback(data => {
        if (data.error) {
            mobiscroll.toast({message: locale.login.k, color: 'danger'});
        }
        if (data.toast) {
            mobiscroll.toast({message: locale.login.l, color: 'info'});
        }
    }, [locale.login.k, locale.login.l]);

    const GETToken = useCallback(data => {
        if (data.email) {
            setEmail(data.email);
            onPopup({view: 'passwordReset'});
        }
        if (data.error) {
            mobiscroll.toast({message: locale.login.k, color: 'danger'});
            setReset('');
            onPopup({view: 'close'});
        }
    }, [onPopup, locale.login.k, setReset]);

    const POSTAuth = useCallback(data => {
        if (data && data.token) {
            window.localStorage.token = data.token;
            Token(data.token);
        }
        if (data && data.url) {
            setWss(data.url);
        }
        if (data.error && data.email) {
            mobiscroll.toast({message: locale.login.k, color: 'danger'});
        }
        if (data.error && data.password) {
            mobiscroll.toast({message: locale.login.n, color: 'danger'});
        }
        if (data.error && data.reset) {
            mobiscroll.toast({message: locale.login.i, color: 'danger'});
        }
        if (data.toast) {
            mobiscroll.toast({message: locale.login.o, color: 'info'});
        }
        if (reset) {
            setReset('');
            setPassword('');
            setEmail('');
            onPopup({view: 'close'});
        }
    }, [locale.login.k, locale.login.n, locale.login.i, locale.login.o, reset, Token, setWss, onPopup]);

    const GETUsername = useCallback(data => {
        if (data.toast) {
            setNext(false);
        }
        if (data.error) {
            mobiscroll.toast({message: locale.login.k, color: 'danger'});
        }
    }, [locale.login.k]);

    useEffect(() => {
        if (reset) {
            mobiscroll.toast({message: locale.login.r, color: 'info'});
            CheckToken();
        }
    }, [reset, locale.login.r, CheckToken])

    useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'forgot') {
            POSTForgot(response.data);
            setResponse({});
        }
    }, [response, POSTForgot, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'token') {
            GETToken(response.data);
            setResponse({});
        }
    }, [response, GETToken, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'auth') {
            POSTAuth(response.data);
            setResponse({});
        }
    }, [response, POSTAuth, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'username') {
            GETUsername(response.data);
            setResponse({});
        }
    }, [response, GETUsername, setResponse])

    useEffect(() => {
        if (urlSelect) {
            onPopup({view: 'applicationSelect'});
            setUrlSelect(false);
        }
    }, [urlSelect])

    return (
        <div className="mbsc-grid cs-login-main">
            {width > 576 &&
                <div className="mbsc-row mbsc-justify-content-center">
                    <div className="mbsc-col-sm-12 mbsc-col-md-8 mbsc-col-xl-6">
                        <mobiscroll.Card theme="ios" themeVariant="light" className="cs-login-card">
                            <div style={{backgroundImage: `url(${account.logo})`}} className="cs-login-card-logo"></div>
                            <mobiscroll.CardHeader>
                                {next &&
                                    <mobiscroll.CardTitle className="cs-login-card-title">{locale.login.c}</mobiscroll.CardTitle>
                                }
                                {!next &&
                                    <mobiscroll.CardTitle className="cs-login-card-title">{email}</mobiscroll.CardTitle>
                                }
                            </mobiscroll.CardHeader>
                            <mobiscroll.CardContent>
                                <mobiscroll.Form theme="ios" themeVariant="light" noValidate className="cs-login-card-body">
                                    <mobiscroll.FormGroup>
                                        {next &&
                                            <mobiscroll.Input inputStyle="outline" labelStyle="floating" required type="email" name="Email" placeholder={locale.login.a} value={email} onChange={ev => {setEmail(ev.target.value)}}>
                                                {locale.login.a}
                                            </mobiscroll.Input>
                                        }
                                        {!next &&
                                            <mobiscroll.Input inputStyle="outline" labelStyle="floating" minLength="8" type="password" name="Password" placeholder={locale.login.b} passwordToggle={true} icon="none" iconAlign="right" value={password} onChange={ev => {setPassword(ev.target.value)}}>
                                                {locale.login.b}
                                            </mobiscroll.Input>
                                        }
                                    </mobiscroll.FormGroup>
                                    <mobiscroll.FormGroup inset className="cs-login-link">
                                        <a href="#" onClick={() => Forgot()}>{locale.login.e}</a>
                                    </mobiscroll.FormGroup>
                                    {false &&
                                        <mobiscroll.FormGroup inset className="cs-login-link" >
                                            <a href="#" onClick={() => Navigation({view: 'register'})}>{locale.login.d}</a>
                                        </mobiscroll.FormGroup>
                                    }
                                    {!next &&
                                        <mobiscroll.FormGroup inset className="cs-login-card-button-end">
                                            <mobiscroll.Button color="primary" className="cs-login-card-button" onClick={() => Auth()} >{locale.login.c}</mobiscroll.Button>
                                        </mobiscroll.FormGroup>
                                    }
                                    {next &&
                                        <mobiscroll.FormGroup inset className="cs-login-card-button-end">
                                            <mobiscroll.Button color="primary" className="cs-login-card-button" onClick={function(){Next()}} >{locale.login.p}</mobiscroll.Button>
                                        </mobiscroll.FormGroup>
                                    }
                                </mobiscroll.Form>
                            </mobiscroll.CardContent>
                            <mobiscroll.CardFooter className="cs-login-card-footer">
                                <div className="mbsc-row mbsc-justify-content-between">
                                    <div className="mbsc-row mbsc-padding cs-login-card-footer-left cs-login-link mbsc-justify-content-center">
                                        <a href="#" onClick={() => Help()}>{locale.login.g}</a>
                                    </div>
                                    <div className="mbsc-row cs-login-card-footer-right cs-login-link mbsc-justify-content-center" >
                                        <a href="https://smartdocfitness.com/" target="_blank">{locale.login.m}</a>
                                    </div>
                                </div>
                            </mobiscroll.CardFooter>
                        </mobiscroll.Card>
                    </div>
                </div>
            }
            {width < 576 &&
                <div className="mbsc-row mbsc-justify-content-center cs-login-background">
                    <div style={{backgroundImage: `url(${account.logo})`}} className="cs-login-logo"></div>
                </div>
            }
            {width < 576 &&
                <div className="mbsc-row mbsc-justify-content-center cs-login-background">
                    {next &&
                        <mobiscroll.CardTitle className="cs-login-card-title">{locale.login.c}</mobiscroll.CardTitle>
                    }
                    {!next &&
                        <mobiscroll.CardTitle className="cs-login-card-title">{email}</mobiscroll.CardTitle>
                    }
                </div>
            }
            {width < 576 &&
                <div className="mbsc-row cs-login-background">
                    <div className="mbsc-col-12">
                        {next &&
                            <mobiscroll.Input inputStyle="outline" labelStyle="floating" required type="text" name="Email" placeholder={locale.login.a} value={email} onChange={(ev) => {setEmail(ev.target.value)}} >
                                {locale.login.a}
                            </mobiscroll.Input>
                        }
                        {!next &&
                            <mobiscroll.Input inputStyle="outline" labelStyle="floating" required minLength="8" type="Password" name="Password" placeholder={locale.login.b} passwordToggle={true} icon="none" iconAlign="right" value={password} onChange={(ev) => {setPassword(ev.target.value)}} >
                                {locale.login.b}
                            </mobiscroll.Input>
                        }
                    </div>
                </div>
            }
            {width < 576 &&
                <div className="mbsc-row mbsc-justify-content-start cs-login-left cs-login-link cs-login-background">
                    <a href="#" onClick={() => Forgot()}>{locale.login.e}</a>
                </div>
            }
            {width < 576 &&
                <div className="mbsc-row mbsc-justify-content-end cs-login-right-bottom cs-login-background">
                    {!next &&
                        <mobiscroll.Button color="primary" className="cs-login-card-button" onClick={() => Auth()} >{locale.login.c}</mobiscroll.Button>
                    }
                    {next &&
                        <mobiscroll.Button color="primary" className="cs-login-card-button" onClick={function(){Next()}} >{locale.login.p}</mobiscroll.Button>
                    }
                </div>
            }
            {width < 576 &&
                <div className="mbsc-row mbsc-justify-content-between cs-login-help cs-login-background">
                    <div className="mbsc-row mbsc-padding cs-login-footer-left cs-login-link mbsc-justify-content-center">
                        <a href="#" onClick={() => Help()}>{locale.login.g}</a>
                    </div>
                    <div className="mbsc-row mbsc-padding cs-login-footer-right cs-login-link mbsc-justify-content-center">
                        <a href="https://smartdocfitness.com/" target="_blank">{locale.login.q}</a>
                    </div>
                </div>
            }
            {!Empty(locale) && popup.view === 'passwordReset' &&
                <LoginReset
                    locale={locale}
                    popup={popup}
                    onPopup={onPopup}
                    setMessage={setMessage}
                    reset={reset}
                    account={account}
                    email={email}
                />
            }
            {!Empty(locale) && popup.view === 'applicationSelect' &&
                <LoginSelect
                    locale={locale}
                    popup={popup}
                    onPopup={onPopup}
                />
            }
            {!Empty(locale) && popup.view === 'loginHelp' &&
                <LoginHelp
                    locale={locale}
                    popup={popup}
                    onPopup={onPopup}
                    setMessage={setMessage}
                    response={response}
                    setResponse={setResponse}
                    account={account}
                />
            }
        </div>
    );
}

export default Login;
