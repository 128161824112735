import React, {useEffect, useState, useCallback} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import './styles/users.css';
import Empty from './utils/Empty';
import UsersUser from './blocks/UsersUser';
import UsersUserNew from './blocks/UsersUserNew';
import UsersGroup from './blocks/UsersGroup';
import UsersGroupNew from './blocks/UsersGroupNew';

function Users({locale, account, setMessage, response, setResponse, onPopup, popup, configuration, setConfiguration, user, navigation, Navigation}) {
    const [userData, setUserData] = useState({});
    const [groupData, setGroupData] = useState({});

    function Tab(data) {
        Navigation({tab: data});

        let message = {};
        message.type = 'wss';
        message.path = data;
        message.action = 'get';
        message.data = {};
        setMessage(message);
    }

    function UsersRows() {
        let item = configuration.mobi.users.length + 5;
        if (item <= 10) {
            return item;
        } else {
            return 10;
        }
    }

    function GroupsRows() {
        if (configuration.mobi.groups.length <= 10) {
            return configuration.mobi.groups.length;
        } else {
            return 10;
        }
    }

    function PatientsRows() {
        if (configuration.mobi.patients.length <= 10) {
            return configuration.mobi.patients.length;
        } else {
            return 10;
        }
    }

    function User(data) {
        let message = {}
        message.type = 'wss';
        message.path = 'user';
        message.action = 'get';
        message.data = {
            user: data
        };
        setMessage(message);
    }

    function Group(data) {
        let message = {}
        message.type = 'wss';
        message.path = 'group';
        message.action = 'get';
        message.data = {
            group: data
        };
        setMessage(message);
    }

    const GETGroups = useCallback(data => {
        if (data.mobiGroups) {
            let item = configuration;
            item.mobi.groups = data.mobiGroups;
            setConfiguration(item);
        }
        mobiscroll.notification.dismiss();
    }, [setConfiguration, configuration]);

    const GETUsers = useCallback(data => {
        if (data.mobiUsers) {
            let item = configuration;
            item.mobi.users = data.mobiUsers;
            setConfiguration(item);
        }
        mobiscroll.notification.dismiss();
    }, [setConfiguration, configuration]);

    const GETUser = useCallback(data => {
        if (data.user) {
            setUserData(data.user);
            onPopup({view: 'user'});
        }
        mobiscroll.notification.dismiss();
    }, [setUserData, onPopup]);

    const POSTUser = useCallback(data => {
        if (data.toast) {
            mobiscroll.toast({message: locale.users.i, color: 'primary'});
            Tab('users');
        } else {
            mobiscroll.notification.dismiss();
        }
    }, [locale.users.i]);

    const DELETEUser = useCallback(data => {
        if (data.mobiUsers) {
            onPopup({view: 'close'});
            setUserData({});
            mobiscroll.toast({message: locale.users.k, color: 'primary'});

            let item = configuration;
            item.mobi.users = data.mobiUsers;
            setConfiguration(item);
        } else {
            mobiscroll.notification.dismiss();
        }
    }, [locale.users.k, configuration, onPopup, setConfiguration]);

    const POSTUserNew = useCallback(data => {
        if (data.mobiUsers) {
            let item = configuration;
            item.mobi.users = data.mobiUsers;
            setConfiguration(item);
        }
        if (data.error) {
            mobiscroll.toast({message: locale.users.j, color: 'warning'});
        } else if (data.user) {
            User(data.user);
        } else {
            mobiscroll.notification.dismiss();
        }
    }, [setConfiguration, configuration, locale.users.j]);

    const GETGroup = useCallback(data => {
        if (data.group) {
            setGroupData(data.group);
            onPopup({view: 'group'});
        }
        mobiscroll.notification.dismiss();
    }, [setGroupData, onPopup]);

    const POSTGroup = useCallback(data => {
        if (data.toast) {
            mobiscroll.toast({message: locale.users.i, color: 'primary'});
            Tab('groups');
        } else {
            mobiscroll.notification.dismiss();
        }
    }, [locale.users.i]);

    const DELETEGroup = useCallback(data => {
        if (data.mobiGroups) {
            onPopup({view: 'close'});
            setGroupData({});
            if (data.mobiGroups.length === 0) {
                Tab('users');
            }
            mobiscroll.toast({message: locale.users.l, color: 'primary'});

            let item = configuration;
            item.mobi.groups = data.mobiGroups;
            setConfiguration(item);
        } else {
            mobiscroll.notification.dismiss();
        }
    }, [locale.users.l, configuration, onPopup, setConfiguration]);

    const POSTGroupNew = useCallback(data => {
        if (data.mobiGroups) {
            let item = configuration;
            item.mobi.groups = data.mobiGroups;
            setConfiguration(item);
        }
        if (data.error) {
            mobiscroll.toast({message: locale.users.j, color: 'warning'});
        } else if (data.group) {
            Group(data.group);
        } else {
            mobiscroll.notification.dismiss();
        }
    }, [setConfiguration, configuration, locale.users.j]);

    useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'user') {
            POSTUser(response.data);
            setResponse({});
        }
    }, [response, POSTUser, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'delete' && response.path === 'user') {
            DELETEUser(response.data);
            setResponse({});
        }
    }, [response, DELETEUser, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'userNew') {
            POSTUserNew(response.data);
            setResponse({});
        }
    }, [response, POSTUserNew, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'groupNew') {
            POSTGroupNew(response.data);
            setResponse({});
        }
    }, [response, POSTGroupNew, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'group') {
            POSTGroup(response.data);
            setResponse({});
        }
    }, [response, POSTGroup, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'groups') {
            GETGroups(response.data);
            setResponse({});
        }
    }, [response, GETGroups, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'group') {
            GETGroup(response.data);
            setResponse({});
        }
    }, [response, GETGroup, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'delete' && response.path === 'group') {
            DELETEGroup(response.data);
            setResponse({});
        }
    }, [response, DELETEGroup, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'users') {
            GETUsers(response.data);
            setResponse({});
        }
    }, [response, GETUsers, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'get' && response.path === 'user') {
            GETUser(response.data);
            setResponse({});
        }
    }, [response, GETUser, setResponse])

    useEffect(() => {
        Tab('users');
    }, []);

    return (
        <div className="mbsc-grid">
            <div className="mbsc-row">
                <div className="mbsc-col-12">
                    <mobiscroll.Card theme="ios" themeVariant="light">
                        <mobiscroll.TabNav className="cs-users-nav" display="inline">
                            {account.options.practice && user.options.doctor &&
                                <mobiscroll.NavItem selected={navigation.tab === 'patients'} onClick={(ev) => {Tab('patients')}} ><mobiscroll.Button color="success" className="cs-users-nav-button" flat={navigation.tab === 'patients'}>{locale.users.a}</mobiscroll.Button></mobiscroll.NavItem>
                            }
                            {(user.options.admin || user.options.owner) &&
                                <mobiscroll.NavItem selected={navigation.tab === 'users'} onClick={(ev) => {Tab('users')}} ><mobiscroll.Button color="warning" className="cs-users-nav-button" flat={navigation.tab === 'users'}>{locale.users.d}</mobiscroll.Button></mobiscroll.NavItem>
                            }
                            {account.options.fitness && (user.options.admin || user.options.owner || user.options.group) &&
                                <mobiscroll.NavItem selected={navigation.tab === 'groups'} onClick={(ev) => {Tab('groups')}}><mobiscroll.Button color="info" className="cs-users-nav-button" flat={navigation.tab === 'groups'}>{locale.users.e}</mobiscroll.Button></mobiscroll.NavItem>
                            }
                        </mobiscroll.TabNav>
                        <mobiscroll.CardContent className="cs-users-device-select">
                            {navigation.tab === 'patients' &&
                                <mobiscroll.Form>
                                    {configuration.mobi.patients.length !== 0 &&
                                        <mobiscroll.Select rows={PatientsRows()} display="inline" select='single' touchUi={false} onSet={function(event, inst) {User(inst.getVal()); inst.clear();}} filter={true} data={configuration.mobi.patients} buttons={[]} />
                                    }
                                    {configuration.mobi.patients.length === 0 &&
                                        <div className="mbsc-row mbsc-justify-content-center">
                                            <mobiscroll.Button disabled flat={true}>{locale.users.b}</mobiscroll.Button>
                                        </div>
                                    }
                                </mobiscroll.Form>
                            }
                            {navigation.tab === 'users' &&
                                <mobiscroll.Form>
                                    {configuration.mobi.users.length !== 0 &&
                                        <mobiscroll.Select rows={UsersRows()} group={true} display="inline" select='single' touchUi={false} onSet={function(event, inst) {User(inst.getVal()); inst.clear();}} filter={true} data={configuration.mobi.users} buttons={[]} />
                                    }
                                    {configuration.mobi.users.length === 0 &&
                                        <div className="mbsc-row mbsc-justify-content-center">
                                            <mobiscroll.Button disabled flat={true}>{locale.users.c}</mobiscroll.Button>
                                        </div>
                                    }
                                </mobiscroll.Form>
                            }
                            {navigation.tab === 'groups' &&
                                <mobiscroll.Form>
                                    {configuration.mobi.groups.length !== 0 &&
                                        <mobiscroll.Select rows={GroupsRows()} display="inline" select='single' touchUi={false} onSet={function(event, inst) {Group(inst.getVal()); inst.clear();}} filter={true} data={configuration.mobi.groups} buttons={[]} />
                                    }
                                    {configuration.mobi.groups.length === 0 &&
                                        <div className="mbsc-row mbsc-justify-content-center">
                                            <mobiscroll.Button disabled flat={true}>{locale.users.h}</mobiscroll.Button>
                                        </div>
                                    }
                                </mobiscroll.Form>
                            }
                        </mobiscroll.CardContent>
                        <mobiscroll.CardFooter>
                            <div className="mbsc-grid">
                                <div className="mbsc-row mbsc-justify-content-between">
                                    <div className="mbsc-row cs-users-footer-left"></div>
                                    <div className="mbsc-row cs-users-footer-right">
                                        {navigation.tab === 'users' &&
                                            <mobiscroll.Button onClick={() => {onPopup({view: 'userNew'})}} flat={true} color="warning">{locale.users.f}</mobiscroll.Button>
                                        }
                                        {navigation.tab === 'groups' &&
                                            <mobiscroll.Button onClick={() => {onPopup({view: 'groupNew'})}} flat={true} color="info">{locale.users.g}</mobiscroll.Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </mobiscroll.CardFooter>
                    </mobiscroll.Card>
                </div>
            </div>
            {popup.view === 'user' &&
                <UsersUser
                    locale={locale}
                    userData={userData}
                    popup={popup}
                    onPopup={onPopup}
                    setMessage={setMessage}
                    setUserData={setUserData}
                    user={user}
                    account={account}
                    users={configuration.mobi.users.length}
                />
            }
            {popup.view === 'userNew' &&
                <UsersUserNew
                    locale={locale}
                    popup={popup}
                    onPopup={onPopup}
                    setMessage={setMessage}
                />
            }
            {popup.view === 'group' &&
                <UsersGroup
                    locale={locale}
                    groupData={groupData}
                    popup={popup}
                    onPopup={onPopup}
                    setMessage={setMessage}
                    setGroupData={setGroupData}
                    configuration={configuration}
                    response={response}
                    setResponse={setResponse}
                />
            }
            {popup.view === 'groupNew' &&
                <UsersGroupNew
                    locale={locale}
                    popup={popup}
                    onPopup={onPopup}
                    setMessage={setMessage}
                />
            }
        </div>
    );
}

export default Users;
