import React, {useCallback, useState, useEffect} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import '../styles/headerHelp.css';
import Empty from "../utils/Empty";

function HeaderHelp({locale, popup, onPopup, setMessage, response, setResponse}) {
  const [text, setText] = useState('');
  const [priority, setPriority] = useState('low');

  const closePopup = useCallback(() => {
    onPopup({view: 'close'});
  }, [onPopup]);

  function Help() {
    if (!text) {
      mobiscroll.toast({message: locale.headerHelp.a, color: 'warning'});
    } else {
      mobiscroll.notification.dismiss();
      mobiscroll.toast({message: locale.headerHelp.b, duration: false, color: 'primary'});

      let message = {}
      message.type = 'wss';
      message.path = 'help';
      message.action = 'post';
      message.data = {};
      message.data.text = text;
      message.data.priority = priority;

      setMessage(message);
    }
  }

  function POSTHelp(data) {
    if (data.toast) {
      closePopup();
      mobiscroll.toast({message: locale.headerHelp.c, color: 'primary'});
    }
  }

  useEffect(() => {
    if (!Empty(response) && response.action === 'post' && response.path === 'help') {
      POSTHelp(response.data);
      setResponse({});
    }
  }, [response, setResponse])

  return (
      <Popup className="cs-header-help-popup" theme="ios" themeVariant="light" display="center" scrollLock={false} fullScreen={true} maxHeight="700" headerText={locale.headerHelp.d} buttons={[{text: locale.headerHelp.e, cssClass: 'cs-users-group-popup-save', handler: function (event) {Help()}}]} isOpen={popup.view === 'help'} onClose={closePopup}>
        <mobiscroll.Form className="cs-header-help-text">
          <mobiscroll.Dropdown inputStyle="box" labelStyle="floating" label={locale.headerHelp.f} value={priority} onChange={function (event, inst) {setPriority(event.target.value)}}>
            <option value="low">{locale.headerHelp.g}</option>
            <option value="medium">{locale.headerHelp.h}</option>
            <option value="high">{locale.headerHelp.i}</option>
          </mobiscroll.Dropdown>
          <mobiscroll.Textarea inputStyle="box" labelStyle="floating" placeholder={locale.headerHelp.j} value={text} onChange={(ev) => {setText(ev.target.value)}}>
            {locale.headerHelp.k}
          </mobiscroll.Textarea>
        </mobiscroll.Form>
      </Popup>
  );
}

export default HeaderHelp;
