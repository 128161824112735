import React, {useCallback, useState, useEffect} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import '../styles/fitnessDevice.css';
import deviceImage from "../images/deviceM5.png";
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBatteryFull, faBatteryBolt, faBatteryHalf, faBatteryQuarter, faBatteryEmpty, faBatteryThreeQuarters} from "@fortawesome/pro-duotone-svg-icons";
import dayjs from "dayjs";

function FitnessDevice({locale, popup, onPopup, response, setResponse, setMessage, configuration, setConfiguration, device, setDevice, devices, setDevices}) {
    const [draft, setDraft] = useState('');
    const [sync, setSync] = useState('');
    const [bytesArray, setBytesArray] = useState([]);
    const [bytes, setBytes] = useState([]);
    const [ble, setBLE] = useState('');
    const [language, setLanguage] = useState(device.state?.language);
    const [unit, setUnit] = useState(device.state?.unit);
    const [timeFormat, setTimeFormat] = useState(device.state?.timeFormat);
    const [upHander, setUpHander] = useState(device.state?.upHander);
    const [notify, setNotify] = useState(device.state?.notify);
    const [heart, setHeart] = useState(device.heart);
    const [user, setUser] = useState(device.user);

    const closePopup = useCallback(() => {
        setUser('');
        setLanguage('');
        setUnit('');
        setTimeFormat('');
        setUpHander(false);
        setNotify(false);
        setHeart(false);
        onPopup({view: 'close'});
    }, [onPopup]);

    function ConnectionState() {
        if (devices.length !== 0) {
            return devices.some((item) =>
                item.data.name === device.name &&
                item.device &&
                item.device.gatt &&
                item.device.gatt.connected
            );
        } else {
            return false;
        }
    }

    function Pair() {
        if (ble) {
            mobiscroll.toast({message: locale.fitnessDevice.x, color: 'warning', display: 'top'});
        } else {
            let item = {
                data: device
            };
            let arrayDevices = devices;
            let arraySelector = configuration.mobi.devices;

            navigator.bluetooth.requestDevice({filters: [{name: item.data.name}], optionalServices: [configuration.ble.service]}).then(dataDevice => {
                item.data.id = dataDevice.id;
                item.device = dataDevice;

                return item.device.gatt.connect();
            }).then(dataServer => {
                item.server = dataServer

                return dataServer.getPrimaryService(configuration.ble.service);
            }).then(dataService => {
                item.service = dataService;

                return Promise.all([
                    dataService.getCharacteristic(configuration.ble.read).then(SetReadCharacteristic),
                    dataService.getCharacteristic(configuration.ble.write).then(SetWriteCharacteristic),
                ]);
            }).catch(error => {
                mobiscroll.notification.dismiss();
                mobiscroll.toast({message: locale.fitnessDevice.ah, color: 'warning'});
            });

            function SetReadCharacteristic(dataCharacteristic) {
                item.characteristic_notify = dataCharacteristic;
                item.characteristic_notify.startNotifications();
                item.characteristic_notify.addEventListener('characteristicvaluechanged', Receive);
            }

            function SetWriteCharacteristic(dataCharacteristic) {
                item.characteristic = dataCharacteristic;

                arrayDevices = arrayDevices.map(function(dataArrayDevices) {
                    if (dataArrayDevices.data.name === item.data.name) {
                        dataArrayDevices.data.id = item.data.id;
                        dataArrayDevices.device = item.device;
                        dataArrayDevices.server = item.server;
                        dataArrayDevices.service = item.service;
                        dataArrayDevices.characteristic_notify = item.characteristic_notify;
                        dataArrayDevices.characteristic = item.characteristic;
                    }
                    return dataArrayDevices;
                });

                arraySelector = arraySelector.map(function(dataArraySelector) {
                    if (dataArraySelector.value === item.data.name) {
                        dataArraySelector.text = dataArraySelector.text + ' | Connected';
                    }
                    return dataArraySelector;
                });

                let itemConfiguration = configuration;
                itemConfiguration.mobi.devices = arraySelector;
                setConfiguration(itemConfiguration);

                setDevices(arrayDevices);
                BLE({command: 'pair', device: item.data.name});
            }
        }
    }

    function Delete() {
        if (ble) {
            mobiscroll.toast({message: locale.fitnessDevice.x, color: 'warning', display: 'top'});
        } else {
            mobiscroll.confirm({
                title: locale.fitnessDevice.z,
                message: locale.fitnessDevice.af,
                okText: locale.fitnessDevice.z,
                cancelText: locale.fitnessDevice.ag,
                callback: (res) => {
                    if (res) {
                        let message = {};
                        message.type = 'wss';
                        message.path = 'device';
                        message.action = 'delete';
                        message.data = {
                            name: device.name
                        };
                        setMessage(message);
                    }
                }
            });
        }
    }

    function DeviceUpdate(data) {
        if (ble) {
            mobiscroll.toast({message: locale.fitnessDevice.x, color: 'warning', display: 'top'});
        } else {
            mobiscroll.toast({message: locale.fitnessDevice.w, duration: false, color: 'primary'});
            let array = devices;
            let state = {};

            array = array.map(function(item) {
                if (item.data.name === device.name) {
                    if (data === 'language') {
                        item.data.state.language = language;
                    }
                    if (data === 'unit') {
                        item.data.state.unit = unit;
                    }
                    if (data === 'timeFormat') {
                        item.data.state.timeFormat = timeFormat;
                    }
                    if (data === 'upHander') {
                        item.data.state.upHander = upHander;
                    }
                    if (data === 'notify') {
                        item.data.state.notify = notify;
                    }
                    if (data === 'heart') {
                        item.data.heart = heart;
                    }
                    state = item.data.state;
                }
                return item;
            });
            setDevices(array);

            if (data.heart === true || data.heart === false) {
                BLE({command: 'heart', device: device.name, heart: heart});
            } else {
                BLE({command: 'state', device: device.name, state: state});
            }
        }
    }

    function Bytes() {
        let array = [];

        if (bytesArray.length === 0 && configuration.bytes.firstByte.includes(bytes[0]) && configuration.bytes.secondByte.includes(bytes[1])) {
            array = bytes;
            setBytesArray(array);
        } else if (bytesArray.length !== 0 && bytes.length !== 0) {
            array = bytesArray;
            array = array.concat(bytes);

            if (!configuration.bytes.arraySize.includes(array.length)) {
                setBytesArray(array);
            }
        }
        if (configuration.bytes.arraySize.includes(array.length) || array.length === 0) {
            if (array.length === 0) {
                array = bytes;
            }
            if (bytesArray.length !== 0) {
                setBytesArray([]);
            }

            let message = {};
            message.type = 'wss';
            message.path = 'ble';
            message.action = 'post';
            message.data = {
                ble: ble,
                bytes: array
            };
            setMessage(message);
        }
    }

    function BLE(data) {
        if (ble && data.button) {
            mobiscroll.toast({message: locale.fitnessDevice.x, color: 'warning', display: 'top'});
        } else {
            let message = {};
            message.type = 'wss';
            message.path = 'ble';
            message.action = 'get';
            message.data = {
                ble: ble,
                device: data.device,
                command: data.command
            };
            if (data.command === 'date') {
                message.data.date = {};
                message.data.date.year = dayjs().format('YYYY');
                message.data.date.month = dayjs().format('M');
                message.data.date.day = dayjs().format('D');
                message.data.date.hour = dayjs().format('H');
                message.data.date.minute = dayjs().format('m');
                message.data.date.seconds = dayjs().format('s');
            }
            if (data.command === 'sync') {
                message.data.date =  dayjs().format('YYYY-M-D');
                message.data.tz =  dayjs().format('Z');
            }
            if (data.ble) {
                message.data.ble =  data.ble;
            }
            if (data.state) {
                message.data.state =  data.state;
            }
            if (data.heart === true || data.heart === false) {
                message.data.heart =  data.heart;
            }
            setMessage(message);
        }
    }

    function Sync() {
        mobiscroll.notification.dismiss();
        mobiscroll.toast({message: locale.fitnessDevice.ae, duration: false, color: 'primary'});
        BLE({command: 'sync', device: device.name, button: true});
    }

    function Receive(data) {
        let buffer = new Uint8Array(data.target.value.buffer);
        let bytes = Array.from(buffer);
        setBytes(bytes);
    }

    function Find() {
        mobiscroll.toast({message: locale.fitnessDevice.d, duration: false, color: 'primary'});
        BLE({command: 'find', device: device.name, button: true});
    }

    function Owner(data) {
        let array = devices;
        array = array.map(function(item){
            if (item.data.name === device.name) {
                if (data === 'none') {
                    item.data.user = null;
                } else {
                    item.data.user = data;
                }
            }
            return item;
        })
        setDevices(array);

        array = configuration.mobi.devices;

        for(let i = 0; i < array.length; i++) {
            let textArray = array[i].text.split(' | ');

            if(textArray[0] === device.name) {
                if(data === 'none') {
                    array[i].text = textArray[0];
                    if(textArray[2] === 'Connected') {
                        array[i].text += ' | Connected';
                    }
                } else {
                    array[i].text = `${textArray[0]} | ${data}`;
                    if(textArray[2] === 'Connected') {
                        array[i].text += ' | Connected';
                    }
                }
                break;
            }
        }
        let item = configuration;
        item.mobi.devices = array;
        setConfiguration(item);

        let message = {};
        message.type = 'wss';
        message.path = 'owner';
        message.action = 'post';
        message.data = {
            name: device.name,
            user: data,
            connected: ConnectionState()
        };
        setMessage(message);
    }

    function POSTBLE(data) {
        if (data.command && data.ble) {
            setBytesArray([]);
            setBLE(data.ble);
            BLE({command: data.command, device: data.device, ble: data.ble});
        } else if (data.command && !data.ble) {
            setBytesArray([]);
            setBLE('');
            BLE({command: data.command, device: data.device});
        } else if (!data.command && !data.ble) {
            mobiscroll.notification.dismiss();
            setBytesArray([]);
            setBLE('');
        }
        if (data.property) {
            let array = devices;

            array = array.map(function(item) {
                if (item.data.name === data.device) {
                    item.data = Object.assign(item.data, data.property);
                    setDevice(item.data);

                    if (data.property.heart) {
                        setHeart(data.property.heart);
                    }
                    if (data.property.state) {
                        setLanguage(data.property.state.language);
                        setUnit(data.property.state.unit);
                        setTimeFormat(data.property.state.timeFormat);
                        setUpHander(data.property.state.upHander);
                        setNotify(data.property.state.notify);
                    }
                }
                return item;
            });
            setDevices(array);
        }
    }

    function GETBLE(data) {
        if (data.ble) {
            setBLE(data.ble);
        }
        if (data.bytes) {
            let array = devices;
            array.map(function(item) {
                if (item.data.name === data.device) {
                    const k = new Uint8Array(data.bytes);
                    item.characteristic.writeValueWithoutResponse(k);
                }
                return item;
            });
        }
    }

    const POSTOwner = useCallback(data => {
        if (data.toast) {
            mobiscroll.toast({message: locale.fitnessDevice.c, color: 'primary'});
        }
    }, [locale.fitnessDevice.c]);

    const DELETEDevice = useCallback(data => {
        if (data.name && devices.length === 1) {
            setDevices([]);
        }
        if (data.name && devices.length > 1) {
            let array = devices;
            let name = data.name;

            array = array.filter(function(item) {
                return item.data.name !== name;
            });
            setDevices(array);
            let mobiArray = configuration.mobi.devices;
            mobiArray = mobiArray.filter(function(item) {
                return item.value !== name;
            });
            let item = configuration;
            item.mobi.devices = mobiArray;
            setConfiguration(item);
        }
        closePopup();
        if (data.toast) {
            mobiscroll.toast({message: locale.fitnessDevice.ad, color: 'primary'});
        }
    }, [devices, locale.fitnessDevice.ad, setDevices]);

    useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'owner') {
            POSTOwner(response.data);
            setResponse({});
        }
    }, [response, POSTOwner, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'delete' && response.path === 'device') {
            DELETEDevice(response.data);
            setResponse({});
        }
    }, [response, DELETEDevice, setResponse])

    useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'ble' ) {
            POSTBLE(response.data);
            setResponse({});
        }
        if (!Empty(response) && response.action === 'get' && response.path === 'ble' ) {
            GETBLE(response.data);
            setResponse({});
        }
    }, [response, setResponse])

    useEffect(() => {
        if (draft) {
            DeviceUpdate(draft);
            setDraft('');
        }
    }, [draft]);

    useEffect(() => {
        if (sync) {
            Sync(sync);
            setSync('');
        }
    }, [sync]);

    useEffect(() => {
        if (bytes.length !== 0) {
            Bytes();
        }
    }, [bytes])

  return (
      <Popup theme="ios" themeVariant="light" display="center" scrollLock={false} fullScreen={true} maxHeight="700" headerText={device.name} buttons={[{text: locale.fitnessDevice.ab, cssClass: 'cs-fitness-device-connect', disabled: ConnectionState(), handler: function (event) {Pair()}},{text: locale.fitnessDevice.y, cssClass: 'cs-fitness-device-find', disabled: !ConnectionState(), handler: function (event) {Find()}}, {text: locale.fitnessDevice.ac, cssClass: 'cs-fitness-device-sync', disabled: !ConnectionState(), handler: function (event) {Sync()}}, {text: locale.fitnessDevice.z, cssClass: 'cs-fitness-device-delete', handler: function (event) {Delete()}}]} isOpen={popup.view === 'device'} onClose={closePopup}>
          <mobiscroll.Form>
              <div className="mbsc-grid">
                  <div className="mbsc-row mbsc-justify-content-center mbsc-padding">
                      <mobiscroll.Avatar src={deviceImage} />
                  </div>
                  {!Empty(device) && ConnectionState() && device.battery && device.current &&
                      <div className="mbsc-row mbsc-justify-content-center">
                          {(device.battery === -1 || device.battery > 100) && <FontAwesomeIcon icon={faBatteryBolt} />}
                          {device.battery === 100 && <FontAwesomeIcon icon={faBatteryFull} />}
                          {device.battery < 100 && device.battery >= 75 && <FontAwesomeIcon icon={faBatteryThreeQuarters} />}
                          {device.battery < 75 && device.battery >= 50 && <FontAwesomeIcon icon={faBatteryHalf} />}
                          {device.battery < 50 && device.battery >= 25 && <FontAwesomeIcon icon={faBatteryQuarter} />}
                          {device.battery < 25 && device.battery >= 0 && <FontAwesomeIcon icon={faBatteryEmpty} />}
                          {device.battery >= 0 && device.battery <= 100 &&
                              <div className="cs-fitness-device-detail-battery">{device.battery} %</div>
                          }
                          {(device.battery === -1 || device.battery > 100) &&
                              <div className="cs-fitness-device-detail-battery">{locale.fitnessDevice.aa}</div>
                          }
                          {device.current &&
                              <div>|&nbsp;&nbsp;{device.current.steps} {locale.fitnessDevice.e}&nbsp;&nbsp;|&nbsp;&nbsp;{device.current.kcalories} {locale.fitnessDevice.f}&nbsp;&nbsp;|&nbsp;&nbsp;{device.current.meters} {locale.fitnessDevice.g}</div>
                          }
                      </div>
                  }
              </div>
              {!Empty(device) &&
                  <mobiscroll.FormGroup>
                      <mobiscroll.Select display="bubble" select='single' filter={true} value={user} touchUi={false} onSet={function(event, inst) {Owner(inst.getVal())}} data={configuration.mobi.users} buttons={[]} >
                          <mobiscroll.Input inputStyle="box" labelStyle="floating">{locale.fitnessDevice.a}</mobiscroll.Input>
                      </mobiscroll.Select>
                  </mobiscroll.FormGroup>
              }
              {!Empty(device) && ConnectionState() &&
                  <mobiscroll.FormGroup>
                      <mobiscroll.Dropdown inputStyle="box" labelStyle="floating" label={locale.fitnessDevice.k} value={language} onChange={(ev) => {setLanguage(ev.target.value); setDraft('language')}}>
                          <option value="English">{locale.fitnessDevice.l}</option>
                          <option value="Chinese">{locale.fitnessDevice.m}</option>
                          <option value="Russian">{locale.fitnessDevice.n}</option>
                          <option value="German">{locale.fitnessDevice.o}</option>
                          <option value="Japanese">{locale.fitnessDevice.p}</option>
                      </mobiscroll.Dropdown>
                      <mobiscroll.Dropdown inputStyle="box" labelStyle="floating" label={locale.fitnessDevice.q} value={unit} onChange={(ev) => {setUnit(ev.target.value); setDraft('unit')}}>
                          <option value="Metric">{locale.fitnessDevice.r}</option>
                          <option value="Imperial">{locale.fitnessDevice.s}</option>
                      </mobiscroll.Dropdown>
                      <mobiscroll.Dropdown inputStyle="box" labelStyle="floating" label={locale.fitnessDevice.t} value={timeFormat} onChange={(ev) => {setTimeFormat(ev.target.value); setDraft('timeFormat')}}>
                          <option value="24 hrs.">{locale.fitnessDevice.u}</option>
                          <option value="12 hrs.">{locale.fitnessDevice.v}</option>
                      </mobiscroll.Dropdown>
                      <mobiscroll.Switch inputStyle="box" checked={heart} onChange={(ev) => {if(heart !== ev.target.checked){setHeart(ev.target.checked); setDraft('heart')}}}>
                          {locale.fitnessDevice.h}
                      </mobiscroll.Switch>
                      <mobiscroll.Switch inputStyle="box" checked={upHander} onChange={(ev) => {if(upHander !== ev.target.checked){setUpHander(ev.target.checked); setDraft('upHander')}}}>
                          {locale.fitnessDevice.i}
                      </mobiscroll.Switch>
                      <mobiscroll.Switch inputStyle="box" checked={notify} onChange={(ev) => {if(notify !== ev.target.checked){setNotify(ev.target.checked); setDraft('notify')}}}>
                          {locale.fitnessDevice.j}
                      </mobiscroll.Switch>
                  </mobiscroll.FormGroup>
              }
          </mobiscroll.Form>
          {!ConnectionState() &&
              <div className="mbsc-row mbsc-justify-content-center">
                  <mobiscroll.Button disabled flat={true}>{locale.fitnessDevice.b}</mobiscroll.Button>
              </div>
          }
      </Popup>
  );
}

export default FitnessDevice;
