import React, {useCallback} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import '../styles/loginSelect.css';

function LoginSelect({locale, popup, onPopup}) {

  const closePopup = useCallback(() => {
    onPopup({view: 'close'});
  }, [onPopup]);

  function Select() {
    window.location.href = 'https://smartdocfitness.com';
  }

  return (
      <Popup theme="ios" themeVariant="light" display="center" closeOnEsc={false} closeOnOverlayClick={false} headerText={locale.loginSelect.a} buttons={[{text: locale.loginSelect.c, cssClass: 'cs-login-select-popup-health', handler: 'cancel'}, {text: locale.loginSelect.d, cssClass: 'cs-login-select-popup-fitness', handler: function (event) {Select()}}]} isOpen={popup.view === 'applicationSelect'} onClose={closePopup}>
        <div className="mbsc-padding">
          <p>{locale.loginSelect.b} <span className="mbsc-bold">{locale.loginSelect.d}</span> {locale.loginSelect.e}</p>
        </div>
        <div className="mbsc-padding">
          <p>{locale.loginSelect.f} <span className="mbsc-bold">{locale.loginSelect.c}</span> {locale.loginSelect.g}</p>
        </div>
      </Popup>
  );
}

export default LoginSelect;
