import React, {useCallback, useState, useEffect} from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import './styles/register.css';
import Empty from './utils/Empty';

function Register({locale, response, setResponse, Navigation, setMessage, account, invite}) {
    const [toggle, setToggle] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [group, setGroup] = useState('');
    const [gender, setGender] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [communicationsSms, setCommunicationsSms] = useState(false);
    const [communicationsVoice, setCommunicationsVoice] = useState(false);
    const [communicationsEmail, setCommunicationsEmail] = useState(false);
    const [dob, setDob] = useState('');
    const [tos, setTos] = useState(false);
    const [language, setLanguage] = useState(window.localStorage.locale);

    function Submit() {
        console.log('test');
        if (!email || !password || !firstName || !lastName) {
            mobiscroll.toast({message: locale.auth.q, color: 'warning'});
        } else if (password !== confirm) {
            mobiscroll.toast({message: locale.auth.x, color: 'warning'});
        } else {
            let communications = {};
            communications.sms = communicationsSms;
            communications.voice = communicationsVoice;
            communications.email = communicationsEmail;
            if (gender === 'n/a') {
                setGender('');
            }
            let item = mobile.replace(/[()\s-]/g, '');

            let message = {};
            message.type = 'rest';
            message.path = 'register';
            message.action = 'post';
            message.data = {
                locale: language,
                email: email,
                firstName: firstName,
                lastName: lastName,
                mobile: item,
                password: password,
                account: account.name,
                group: group,
                gender: gender,
                height: height,
                weight: weight,
                communications: communications,
                dob: dob,
                tos: tos
            };
            setMessage(message);
        }
    }

    function Mobile(data) {
        const cleanedText = data.replace(/[^\d]/g, '');
        let formattedText = '';
        if (cleanedText.length <= 3) {
            formattedText = cleanedText;
        } else if (cleanedText.length <= 6) {
            formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3)}`;
        } else {
            formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3, 6)}-${cleanedText.slice(6, 10)}`;
        }
        setMobile(formattedText);
    }

    function NumberHeight(data) {
        const re = /^[0-9\b]+$/;

        if (data === "" || (re.test(data) && (data <= 300))) {
            setHeight(data);
        }
    }

    function NumberWeight(data) {
        const re = /^[0-9\b]+$/;

        if (data === "" || (re.test(data) && (data <= 300))) {
            setWeight(data);
        }
    }

    function Login() {
        Navigation({ view:'login' });
    }

    const POSTRegister = useCallback(data => {
        if (data.error && data.user) {
            mobiscroll.toast({message: locale.auth.t, color: 'warning'});
        }
        if (data.error && data.code) {
            mobiscroll.toast({message: locale.auth.u, color: 'warning'});
        }
        if (data.error && data.group) {
            mobiscroll.toast({message: locale.auth.w, color: 'warning'});
        }
        if (data.toast) {
            mobiscroll.toast({message: locale.auth.v, color: 'primary'});
            Navigation({ view: 'login' });
            setFirstName('');
            setLastName('');
            setMobile('');
            setConfirm('');
            setGroup('');
            setGender('');
            setHeight('');
            setWeight('');
            setDob('');
        }
    }, [Navigation, locale.auth.t, locale.auth.u, locale.auth.w, locale.auth.v]);

    useEffect(() => {
        if (!Empty(response) && response.action === 'post' && response.path === 'register') {
            POSTRegister(response.data);
            setResponse({});
        }
    }, [response, POSTRegister, setResponse])

    useEffect(() => {
            if (invite) {
                setGroup(invite);
            }
        },
        [invite],
    );

    useEffect(() => {
            if (toggle === 'sms' && communicationsSms) {
                setCommunicationsSms(false);
            } else if (toggle === 'sms' && !communicationsSms) {
                setCommunicationsSms(true);
            }
            if (toggle === 'voice' && communicationsVoice) {
                setCommunicationsVoice(false);
            } else if (toggle === 'voice' && !communicationsVoice) {
                setCommunicationsVoice(true);
            }
            if (toggle === 'email' && communicationsEmail) {
                setCommunicationsEmail(false);
            } else if (toggle === 'email' && !communicationsEmail) {
                setCommunicationsEmail(true);
            }
            if (toggle) {
                setToggle('')
            }
        },
        [toggle],
    );

    return (
        <div className="mbsc-grid">
            <div className="mbsc-row mbsc-justify-content-center">
                <div className="mbsc-col-sm-12 mbsc-col-md-11 mbsc-col-xl-10">
                    {!tos &&
                        <mobiscroll.Card theme="ios" themeVariant="light" className="mbsc-padding">
                            <mobiscroll.CardHeader>
                                <mobiscroll.CardTitle className="cs-register-title">
                                    <h4>{locale.privacy.a}</h4>
                                </mobiscroll.CardTitle>
                            </mobiscroll.CardHeader>
                            <mobiscroll.CardContent>
                                <p>{locale.privacy.b}</p>
                            </mobiscroll.CardContent>
                            <div className="cs-register-form-privacy mbsc-padding" theme="ios" themeVariant="light" noValidate>
                                <h4>{locale.privacy.c}</h4>
                                <p>{locale.privacy.d}</p>
                                <ul>
                                    <li>{locale.privacy.e}</li>
                                    <li>{locale.privacy.f}</li>
                                    <li>{locale.privacy.g}</li>
                                    <li>{locale.privacy.h}</li>
                                </ul>
                                <h4>{locale.privacy.i}</h4>
                                <p>{locale.privacy.j}</p>
                                <ul>
                                    <li>{locale.privacy.k}</li>
                                    <li>{locale.privacy.l}</li>
                                    <li>{locale.privacy.m}</li>
                                    <li>{locale.privacy.n}</li>
                                </ul>
                                <h4>{locale.privacy.o}</h4>
                                <p>{locale.privacy.p}</p>
                                <h4>{locale.privacy.q}</h4>
                                <p>{locale.privacy.r}</p>
                                <h4>{locale.privacy.s}</h4>
                                <p>{locale.privacy.t}</p>
                                <h4>{locale.privacy.u}</h4>
                                <p>{locale.privacy.v}</p>
                            </div>
                            <mobiscroll.CardContent>
                                <p>{locale.privacy.w}</p>
                                <div className="mbsc-btn-group-block">
                                    <mobiscroll.Button className="cs-register-button mbsc-btn-primary mbsc-bold" onClick={() => setTos(true)} block={true}>{locale.privacy.x}</mobiscroll.Button>
                                </div>
                            </mobiscroll.CardContent>
                        </mobiscroll.Card>
                    }
                    {tos &&
                        <mobiscroll.Card theme="ios" themeVariant="light" className="mbsc-padding">
                            <mobiscroll.CardContent className="mbsc-padding">
                                <p>{locale.register.a}</p>
                                <mobiscroll.Input inputStyle="box" labelStyle="floating" type="email" name="Email" placeholder={locale.register.b} value={email} onChange={(ev) => {setEmail(ev.target.value)}}>
                                    {locale.register.b}
                                </mobiscroll.Input>
                                <mobiscroll.Input inputStyle="box" labelStyle="floating" type="text" name="First Name" placeholder={locale.register.c} value={firstName} onChange={(ev) => {setFirstName(ev.target.value)}}>
                                    {locale.register.c}
                                </mobiscroll.Input>
                                <mobiscroll.Input inputStyle="box" labelStyle="floating" type="text" name="Last Name" placeholder={locale.register.d} value={lastName} onChange={(ev) => {setLastName(ev.target.value)}}>
                                    {locale.register.d}
                                </mobiscroll.Input>
                                <mobiscroll.Input inputStyle="box" labelStyle="floating" type="text" name="Mobile" placeholder={locale.register.e} value={mobile} onChange={(ev) => {Mobile(ev.target.value)}}>
                                    {locale.register.e}
                                </mobiscroll.Input>
                                <mobiscroll.Input inputStyle="box" labelStyle="floating" minLength="8" type="password" name="Password" placeholder={locale.register.f} passwordToggle={true} icon="none" iconAlign="right" value={password} onChange={(ev) => {setPassword(ev.target.value)}}>
                                    {locale.register.f}
                                </mobiscroll.Input>
                                <mobiscroll.Input inputStyle="box" labelStyle="floating" minLength="8" type="password" name="Confirm Password" placeholder={locale.register.g} passwordToggle={true} icon="none" iconAlign="right" value={confirm} onChange={(ev) => {setConfirm(ev.target.value)}}>
                                    {locale.register.g}
                                </mobiscroll.Input>
                                <mobiscroll.Input disabled={invite} inputStyle="box" labelStyle="floating" type="text" name="Group" placeholder={locale.register.ag} value={group} onChange={(ev) => {setGroup(ev.target.value)}}>
                                    {locale.register.ag}
                                </mobiscroll.Input>
                                <mobiscroll.Dropdown inputStyle="box" labelStyle="floating" label={locale.register.i} value={gender} onChange={function (event, inst) {setGender(event.target.value)}}>
                                    <option value="male">{locale.register.j}</option>
                                    <option value="female">{locale.register.k}</option>
                                    <option value="n/a">{locale.register.ah}</option>
                                </mobiscroll.Dropdown>
                                <mobiscroll.Input inputStyle="box" labelStyle="floating" type="text" name="Height" placeholder={locale.register.m} value={height} onChange={(ev) => {NumberHeight(ev.target.value)}}>
                                    {locale.register.l}
                                </mobiscroll.Input>
                                <mobiscroll.Input inputStyle="box" labelStyle="floating" type="text" name="Weight" placeholder={locale.register.o} value={weight} onChange={(ev) => {NumberWeight(ev.target.value)}}>
                                    {locale.register.n}
                                </mobiscroll.Input>
                                <mobiscroll.Date display="center" buttons={['set']} touchUi={false} lang={language} value={dob} onSet={(event) => {setDob(event.valueText)}}>
                                    <mobiscroll.Input inputStyle="box" labelStyle="floating" placeholder={locale.register.u}>{locale.register.t}</mobiscroll.Input>
                                </mobiscroll.Date>
                                <p>{locale.register.p}</p>
                                <mobiscroll.Switch inputStyle="box" checked={communicationsSms} onChange={(ev) => setToggle('sms')}>
                                    {locale.register.q}
                                </mobiscroll.Switch>
                                <mobiscroll.Switch inputStyle="box" checked={communicationsVoice} onChange={(ev) => setToggle('voice')}>
                                    {locale.register.r}
                                </mobiscroll.Switch>
                                <mobiscroll.Switch inputStyle="box" checked={communicationsEmail} onChange={(ev) => setToggle('email')}>
                                    {locale.register.s}
                                </mobiscroll.Switch>
                                <div className="mbsc-btn-group-block">
                                    <mobiscroll.Button className="cs-register-button mbsc-btn-primary mbsc-bold" onClick={() => Submit()} block={true}>{locale.register.v}</mobiscroll.Button>
                                </div>
                                <p>{locale.register.w}</p>
                            </mobiscroll.CardContent>
                        </mobiscroll.Card>
                    }
                </div>
            </div>
            {tos &&
                <div className="mbsc-row mbsc-justify-content-center">
                    <div>
                        <div className="cs-register-login">{locale.register.x}</div>
                        <div className="mbsc-row mbsc-padding mbsc-justify-content-center cs-register-link">
                            <a href="#" onClick={() => Login}>{locale.register.y}</a>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Register;
