import React, {useCallback, useState, useEffect} from 'react';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import {Popup} from "@mobiscroll/react";
import Mobile from '../utils/Mobile';
import '../styles/usersUser.css';

function UsersUser({userData, locale, popup, onPopup, setMessage, setUserData, user, account, users}) {
  const [toggle, setToggle] = useState('');
  const [alias, setAlias] = useState(userData.alias);
  const [communicationsSms, setCommunicationsSms] = useState(userData.communications.sms);
  const [communicationsVoice, setCommunicationsVoice] = useState(userData.communications.voice);
  const [communicationsEmail, setCommunicationsEmail] = useState(userData.communications.email);
  const [dob, setDob] = useState(userData.dob);
  const [firstName, setFirstName] = useState(userData.firstName);
  const [gender, setGender] = useState(userData.gender);
  const [height, setHeight] = useState(userData.height);
  const [lastName, setLastName] = useState(userData.lastName);
  const [mobile, setMobile] = useState(Mobile({data: userData.mobile, setMobile: null}));
  const [optionsAdmin, setOptionsAdmin] = useState(userData.options.admin);
  const [optionsDoctor, setOptionsDoctor] = useState(userData.options.doctor);
  const [optionsGroup, setOptionsGroup] = useState(userData.options.group);
  const [optionsOwner, setOptionsOwner] = useState(userData.options.owner);
  const [optionsScheduler, setOptionsScheduler] = useState(userData.options.scheduler);
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [unit, setUnit] = useState(userData.unit);
  const [tab, setTab] = useState('account');

  const closePopup = useCallback(() => {
    setUserData({});
    onPopup({view: 'close'});
  }, [onPopup, setUserData]);

  function Save() {
    if (password && password !== confirm) {
      mobiscroll.toast({message: locale.usersUser.ai, color: 'warning'});
    } else {
      mobiscroll.toast({message: locale.usersUser.a, duration: false, color: 'primary'});
      let number = '';
      if (mobile) {
        number = mobile.replace(/[()\s-]/g, '');
      }

      let item = {};
      item.alias = alias;
      item.firstName = firstName;
      item.lastName = lastName;
      item.email = userData.email;
      item.mobile = number;
      item.gender = gender;
      item.dob = dob;
      item.unit = unit;
      item.height = height;
      item.password = password;
      item.communications = {};
      item.communications.sms = communicationsSms;
      item.communications.voice = communicationsVoice;
      item.communications.email = communicationsEmail;
      item.options = {};
      item.options.admin = optionsAdmin;
      item.options.doctor = optionsDoctor;
      item.options.group = optionsGroup;
      item.options.owner = optionsOwner;
      item.options.scheduler = optionsScheduler;

      let message = {}
      message.type = 'wss';
      message.path = 'user';
      message.action = 'post';
      message.data = item;
      setMessage(message);
    }
  }

  function Delete() {
    if (users === 1) {
      mobiscroll.toast({message: locale.usersUser.am, color: 'warning'});
    } else {
      mobiscroll.confirm({
        title: locale.usersUser.aj,
        message: locale.usersUser.ak,
        okText: locale.usersUser.c,
        cancelText: locale.usersUser.al,
        callback: (res) => {
          if (res) {
            let message = {};
            message.type = 'wss';
            message.path = 'user';
            message.action = 'delete';
            message.data = {
              user: userData.email
            };
            setMessage(message);
          }
        }
      });
    }
  }

  function NumberHeight(data) {
    const re = /^[0-9\b]+$/;

    if (data === "" || (re.test(data) && (data <= 300))) {
      setHeight(data);
    }
  }

  useEffect(() => {
        if (toggle === 'sms' && communicationsSms) {
          setCommunicationsSms(false);
        } else if (toggle === 'sms' && !communicationsSms) {
          setCommunicationsSms(true);
        }
        if (toggle === 'voice' && communicationsVoice) {
          setCommunicationsVoice(false);
        } else if (toggle === 'voice' && !communicationsVoice) {
          setCommunicationsVoice(true);
        }
        if (toggle === 'email' && communicationsEmail) {
          setCommunicationsEmail(false);
        } else if (toggle === 'email' && !communicationsEmail) {
          setCommunicationsEmail(true);
        }
        if (toggle === 'owner' && optionsOwner) {
          setOptionsOwner(false);
        } else if (toggle === 'owner' && !optionsOwner) {
          setOptionsOwner(true);
        }
        if (toggle === 'admin' && optionsAdmin) {
          setOptionsAdmin(false);
        } else if (toggle === 'admin' && !optionsAdmin) {
          setOptionsAdmin(true);
        }
        if (toggle === 'doctor' && optionsDoctor) {
          setOptionsDoctor(false);
        } else if (toggle === 'doctor' && !optionsDoctor) {
          setOptionsDoctor(true);
        }
        if (toggle === 'scheduler' && optionsScheduler) {
          setOptionsScheduler(false);
        } else if (toggle === 'scheduler' && !optionsScheduler) {
          setOptionsScheduler(true);
        }
        if (toggle === 'group' && optionsGroup) {
          setOptionsGroup(false);
        } else if (toggle === 'group' && !optionsGroup) {
          setOptionsGroup(true);
        }
        if (toggle) {
          setToggle('')
        }
      },
      [toggle],
  );

  return (
      <Popup className="cs-users-user-popup" theme="ios" themeVariant="light" display="center" scrollLock={false} fullScreen={true} maxHeight="700" headerText={userData.email} buttons={[{text: locale.usersUser.b, cssClass: 'cs-users-user-popup-save', handler: function (event) {Save()}}, {text: locale.usersUser.c, cssClass: 'cs-users-user-popup-delete', handler: function (event) {Delete()}}]} isOpen={popup.view === 'user'} onClose={closePopup}>
        <mobiscroll.TabNav className="cs-users-user-nav" display="inline">
          <mobiscroll.NavItem selected={tab === 'account'} onClick={(ev) => {setTab('account')}} ><mobiscroll.Button color="primary" className="cs-users-user-nav-button" flat={tab === 'account'}>{locale.usersUser.d}</mobiscroll.Button></mobiscroll.NavItem>
          <mobiscroll.NavItem selected={tab === 'settings'} onClick={(ev) => {setTab('settings')}} ><mobiscroll.Button color="success" className="cs-users-user-nav-button" flat={tab === 'settings'}>{locale.usersUser.e}</mobiscroll.Button></mobiscroll.NavItem>
          <mobiscroll.NavItem selected={tab === 'communications'} onClick={(ev) => {setTab('communications')}}><mobiscroll.Button color="warning" className="cs-users-user-nav-button" flat={tab === 'communications'}>{locale.usersUser.f}</mobiscroll.Button></mobiscroll.NavItem>
          <mobiscroll.NavItem selected={tab === 'options'} onClick={(ev) => {setTab('options')}}><mobiscroll.Button color="info" className="cs-users-user-nav-button" flat={tab === 'options'}>{locale.usersUser.g}</mobiscroll.Button></mobiscroll.NavItem>
        </mobiscroll.TabNav>
        <mobiscroll.Form>
          <div className="mbsc-grid">
            <div className="mbsc-row cs-users-user-avatar mbsc-justify-content-center mbsc-padding">
              <mobiscroll.Avatar src={'https://www.gravatar.com/avatar/' + userData.avatar + '?d=retro'} />
            </div>
          </div>
          {tab === 'account' &&
              <mobiscroll.FormGroup inset>
                <mobiscroll.Input labelStyle="floating" type="text" name="Nickname" placeholder={locale.usersUser.h} value={alias} onChange={(ev) => {setAlias(ev.target.value)}}>
                  {locale.usersUser.h}
                </mobiscroll.Input>
                <mobiscroll.Input labelStyle="floating" type="text" name="First Name" placeholder={locale.usersUser.i} value={firstName} onChange={(ev) => {setFirstName(ev.target.value)}}>
                  {locale.usersUser.i}
                </mobiscroll.Input>
                <mobiscroll.Input labelStyle="floating" type="text" name="Last Name" placeholder={locale.usersUser.j} value={lastName} onChange={(ev) => {setLastName(ev.target.value)}}>
                  {locale.usersUser.j}
                </mobiscroll.Input>
                <mobiscroll.Input labelStyle="floating" type="text" name="Mobile" placeholder={locale.usersUser.k} value={mobile} onChange={(ev) => {Mobile({data: ev.target.value, setMobile: setMobile})}}>
                  {locale.usersUser.k}
                </mobiscroll.Input>
                <mobiscroll.Input labelStyle="floating" minLength="8" type="password" name="Password" placeholder={locale.usersUser.l} passwordToggle={true} icon="none" iconAlign="right" value={password} onChange={(ev) => {setPassword(ev.target.value)}}>
                  {locale.usersUser.l}
                </mobiscroll.Input>
                <mobiscroll.Input labelStyle="floating" minLength="8" type="password" name="Confirm Password" placeholder={locale.usersUser.m} passwordToggle={true} icon="none" iconAlign="right" value={confirm} onChange={(ev) => {setConfirm(ev.target.value)}}>
                  {locale.usersUser.m}
                </mobiscroll.Input>
              </mobiscroll.FormGroup>
          }
          {tab === 'settings' &&
              <mobiscroll.FormGroup inset>
                <mobiscroll.Date display="center" buttons={['set']} touchUi={false} lang={user.locale} value={dob} onSet={(event) => {setDob(event.valueText)}}>
                  <mobiscroll.Input labelStyle="floating" placeholder={locale.usersUser.n}>{locale.usersUser.o}</mobiscroll.Input>
                </mobiscroll.Date>
                <mobiscroll.Dropdown labelStyle="floating" label={locale.usersUser.p} value={gender} onChange={function (event, inst) {setGender(event.target.value)}}>
                  <option value="male">{locale.usersUser.q}</option>
                  <option value="female">{locale.usersUser.r}</option>
                  <option value="n/a">{locale.usersUser.s}</option>
                </mobiscroll.Dropdown>
                {unit === 'metric' &&
                    <mobiscroll.Input labelStyle="floating" type="text" name="Height" placeholder={locale.usersUser.t} value={height} onChange={(ev) => {NumberHeight(ev.target.value)}}>
                      {locale.usersUser.u}
                    </mobiscroll.Input>
                }
                {unit === 'imperial' &&
                    <mobiscroll.Input labelStyle="floating" type="text" name="Height" placeholder={locale.usersUser.v} value={height} onChange={(ev) => {NumberHeight(ev.target.value)}}>
                      {locale.usersUser.w}
                    </mobiscroll.Input>
                }
                <mobiscroll.Dropdown labelStyle="floating" label={locale.usersUser.x} value={unit} onChange={function (event, inst) {setUnit(event.target.value)}}>
                  <option value="metric">{locale.usersUser.y}</option>
                  <option value="imperial">{locale.usersUser.z}</option>
                </mobiscroll.Dropdown>
              </mobiscroll.FormGroup>
          }
          {tab === 'communications' &&
              <mobiscroll.FormGroup inset>
                <mobiscroll.Switch checked={communicationsSms} onChange={(ev) => setToggle('sms')}>
                  {locale.usersUser.aa}
                </mobiscroll.Switch>
                <mobiscroll.Switch checked={communicationsVoice} onChange={(ev) => setToggle('voice')}>
                  {locale.usersUser.ab}
                </mobiscroll.Switch>
                <mobiscroll.Switch checked={communicationsEmail} onChange={(ev) => setToggle('email')}>
                  {locale.usersUser.ac}
                </mobiscroll.Switch>
              </mobiscroll.FormGroup>
          }
          {tab === 'options' &&
              <mobiscroll.FormGroup inset>
                {user.options.owner &&
                    <mobiscroll.Switch checked={optionsOwner} onChange={(ev) => setToggle('owner')}>
                      {locale.usersUser.ad}
                    </mobiscroll.Switch>
                }
                <mobiscroll.Switch checked={optionsAdmin} onChange={(ev) => setToggle('admin')}>
                  {locale.usersUser.ae}
                </mobiscroll.Switch>
                {account.options.practice &&
                    <mobiscroll.Switch checked={optionsDoctor} onChange={(ev) => setToggle('doctor')}>
                      {locale.usersUser.af}
                    </mobiscroll.Switch>
                }
                {account.options.practice &&
                    <mobiscroll.Switch checked={optionsScheduler} onChange={(ev) => setToggle('scheduler')}>
                      {locale.usersUser.ag}
                    </mobiscroll.Switch>
                }
                {account.options.fitness &&
                    <mobiscroll.Switch checked={optionsGroup} onChange={(ev) => setToggle('group')}>
                      {locale.usersUser.ah}
                    </mobiscroll.Switch>
                }
              </mobiscroll.FormGroup>
          }
        </mobiscroll.Form>
      </Popup>
  );
}

export default UsersUser;
